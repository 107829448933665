import { defineAsyncComponent } from "vue";
export const abcdViews = {
  discovery: {
    name: "accounts",
    comp: defineAsyncComponent(
      () => import("@/views/abcd/AccountDiscoveryWrapper.vue")
    ),
  },
  login: {
    name: 'login',
    comp: defineAsyncComponent(() => import('@/views/abcd/AALogin.vue'))
  },
  selectBanks: {
    name: 'selectFips',
    comp: defineAsyncComponent(() => import('@/views/abcd/SelectBanks.vue'))
  },
  thankYou: {
    name: 'thank-you',
    comp: defineAsyncComponent(() => import('@/views/abcd/ThankYou.vue'))
  },
  consentPage: {
    name: 'consent-page',
    comp: defineAsyncComponent(() => import('@/views/abcd/ConsentPage.vue'))
  },
  missingAccount: {
    name: 'missing-account',
    comp: defineAsyncComponent(() => import('@/views/abcd/MissingAccount.vue'))
  },
  altMobileNumber: {
    name: 'alt-mobile',
    comp: defineAsyncComponent(() => import('@/views/abcd/EnterMobileNumber.vue'))
  },
}

export const naviViews = {
  discovery: {
    name: "accounts",
    comp: defineAsyncComponent(
      () => import("@/views/navi/AccountDiscovery.vue")
    ),
  },
  login: {
    name: "login",
    comp: defineAsyncComponent(() => import("@/views/navi/AALogin.vue")),
  },
  selectBanks: {
    name: "selectFips",
    comp: defineAsyncComponent(() => import("@/views/navi/SelectBanks.vue")),
  },
}

export const mliViews = {
  discovery: {
    name: 'accounts',
    comp: defineAsyncComponent(() => import('@/views/mli/AccountDiscovery.vue'))
  },
  login: {
    name: 'login',
    comp: defineAsyncComponent(() => import('@/views/mli/AALogin.vue'))
  },
  selectBanks: {
    name: 'selectFips',
    comp: defineAsyncComponent(() => import('@/views/mli/SelectBanks.vue'))
  },
  thankYou: {
    name: 'thank-you',
    comp: defineAsyncComponent(() => import('@/views/mli/ThankYou.vue'))
  },
  consentPage: {
    name: 'consent-page',
    comp: defineAsyncComponent(() => import('@/views/mli/ConsentPage.vue'))
  },
}

export const v2Views = {
  discovery: {
    name: "accounts",
    comp: defineAsyncComponent(
      () => import("@/views/V2-Updated/AccountDiscovery.vue")
    ),
  },
  login: {
    name: "login",
    comp: defineAsyncComponent(() => import("@/views/V2-Updated/AALogin.vue")),
    //comp: AALogin
  },
  selectBanks: {
    name: "selectBanks",
    comp: defineAsyncComponent(
      () => import("@/views/V2-Updated/SelectBanks.vue")
    ),
  },
  aaBanks: {
    name: "selectFips",
    comp: defineAsyncComponent(() => import("@/views/V2-Updated/AABanks.vue")),
  },
  thankYou: {
    name: "thank-you",
    comp: defineAsyncComponent(() => import("@/views/V2-Updated/ThankYou.vue")),
  },
  consentPage: {
    name: "consent-page",
    comp: defineAsyncComponent(
      () => import("@/views/V2-Updated/ConsentPage.vue")
    ),
  },

  errorPage: {
    name: "error-page",
    comp: defineAsyncComponent(
      () => import("@/views/V2-Updated/ErrorPage.vue")
    ),
  },

  xErrorPage: {
    name: "x-errorPage",
    comp: defineAsyncComponent(
      () => import("@/views/V2-Updated/XErrorPage.vue")
    ),
  },

  xProgress: {
    name: "x-progress",
    comp: defineAsyncComponent(
      () => import("@/views/V2-Updated/XConsentProgress.vue")
    ),
  },
};


export const dspViews = {
  discovery: {
    name: 'accounts',
    comp: defineAsyncComponent(() => import('@/views/dsp/AccountDiscovery.vue'))
  },
  login: { 
    name: 'login',
    comp: defineAsyncComponent(() => import('@/views/dsp/AALogin.vue'))
  },
  consentPage: { 
    name: 'consent-page',
    comp: defineAsyncComponent(() => import('@/views/dsp/ConsentPage.vue')) 
  },
  aaInfo: { 
    name: 'aa-info',
    comp: defineAsyncComponent(() => import('@/views/dsp/AAInfo.vue')) 
  },
  altMobileNumber: { 
    name: 'alt-mobile',
    comp: defineAsyncComponent(() => import('@/views/dsp/AltMobileNumber.vue'))
  },
}

export const balicViews = {
  discovery: {
    name: "accounts",
    comp: defineAsyncComponent(
      () => import("@/views/balic/views/AccountDiscovery.vue")
    ),
  },
  consentPage: {
    name: "consent-page",
    comp: defineAsyncComponent(() => import("@/views/balic/views/ConsentPage.vue")),
  },
  selectBanks: {
    name: "selectFips",
    comp: defineAsyncComponent(() => import("@/views/balic/views/SelectBanks.vue")),
  },
}



