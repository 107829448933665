import useBackend from "@/services/api2.service";
import useEventService from "@/services/events.service";
import { AccountFilterParser } from "./aa-filters";
import useUtilService from "@/services/util.service";
import { storeToRefs } from "pinia";
import { Logger } from "tslog";
import { AxiosError } from "axios";
import { useResetStore } from "@/services/reset-store";
import {
  AuthInitRequest,
  LinkedAccount,
  ConsentAction,
} from "@finarkein/aasdk-core";
import {
  COMPONENT_VIEW_TYPE,
  ERROR_RESPONSE_CODES,
  JOURNEY_LAYOUTS,
  JOURNEY_STATUS_ENUM,
  REDIRECT_MODE_ENUM,
  SELECTION_MODE,
} from "@/constants/constants";
import emitter from "@/composables/event.emitter";
import { ResponseWrapper } from "./response-wrapper";
import {
  AccountLinkedResProto,
  AccountLinkingResponseProto,
  DiscoveryStatus,
  FinancialAccount,
  Institution,
  JourneyStoreType,
} from "./models";
import { computed, ref, watch } from "vue";
import { DefaultFeatures } from "./journey-features";
import {
  CAUSE,
  ConductorEvent,
  ConductorFeatures,
  ErrorCodes,
  ErrorMessages,
  ErrorTypes,
  InternalConductorEvent,
  JourneyType,
  LastStatus,
} from "./journey-constants";
import { DefaultTemplates } from "./journey-template";
import moment from "moment";
import { useRoute } from "vue-router";
import router from "@/router";
import useFeatures from "@/services/feature.service";
import { useAAXJourneyStore } from "../store/aaX-journey.store";
import { API_ERROR_RESPONSE_CODES } from "./journey-constants";
import { v2Views } from "./views";


const log = new Logger({
  name: "[conductor-lite]",
  //prefix: ['[conductor-lite]'],
  prettyLogTimeZone: "local",
  hideLogPositionForProduction: true, // process.env.NODE_ENV !== 'production'
});

export const eventPayload = (
  requestId: string,
  discriminator: number,
  fields?: Record<string, any>,
  aaErrorCtx = {}
) => {
  return {
    requestId,
    discriminator,
    timestamp: new Date().toISOString(),
    ...fields,
    ...aaErrorCtx,
  };
};

//store is storeToRefs store, which is two way
export default function useConductor(
  aaStore: JourneyStoreType,
  aaViews: any,
  layoutView: string
) {
  const store = aaStore;
  const views = aaViews;
  const events = useEventService();
  const api = useBackend();
  const utils = useUtilService();
  const eventEmitter = emitter;
  const {
    filterParser,
    lastKnownStatus,
    viewHandler,
    aaSdkAdapter,
    aaHandle,
    extraIdentifiersList,
    aaSdk,
    isProcessing,
    troubleshootError,
    otpReference,
    awaitNext,
    exitWorld,
    aaAuth,
    institutionWiseAccounts,
    autoDiscoveryCount,
    anythingLoading,
    bankFound,
    showOtpSentPopup,
    /* ,otpForUser  */ currentMobile,
    totalAccountsCount,
    consentAction,
    removeListener,
    missingAcc,
    currentView,
    features,
    autoRetryCount,
    previousHandler,
    fipFilters,
    noFipFilters,
    encryptedParams,
    discriminator,
    errorObject,
    linkedAccountsPresent,
    webRedirectUrl,
    tenantId,
    extraUserDetails,
    askAdditionalInfo,
    workingInstitutions,
    somethingLoading,
    
    extraJourneyReq,
    userConsentedAccounts,
   
  } = storeToRefs(store);

  const resetStore = useResetStore();
  const xStore = useAAXJourneyStore();
  const operationWrapper = new ResponseWrapper();

  // v2X data
  const {
    errorXValue,
    xid,
    reqId,
    forTenantRedirectParams,
    showRedirectButton,
    redirectMode,
    customAAJourneyList,
    brandInfo,
    tId,
    sessionError,
  } = storeToRefs(xStore);
  const journeyStatus = JOURNEY_STATUS_ENUM;
  const failedLayoutHandler: Record<string, any> = {
    'v2': handleFailedFlowState,
    'v4': handleFailedFlowState,
    'v5': handleFailedFlowState,
    'v6': handleFailedFlowState,
    'v7': handleFailedFlowState,
    'v8': handleFailedFlowState,
    // Add other layout handlers if needed
  };
  /**
   * Initialize conductor using just the request identifier.
   *
   * @param requestId
   */
  // const consentRes = {} as any;

  const init = async (requestId: string, view?: any) => {
    lastKnownStatus.value = LastStatus.REQUIRES_INIT;
    isProcessing.value = true;
    events.fire(
      ConductorEvent.OPEN,
      eventPayload(requestId, discriminator.value)
    );
    // for V2X as it has query params
    store.preInit(requestId);
    if (
      extraJourneyReq.value !== undefined &&
      extraJourneyReq.value.jtype === JourneyType.ANUBHAV_X
    ) {
      await getXDetails();
      isProcessing.value = false;
    } else if (
      extraJourneyReq.value !== undefined &&
      extraJourneyReq.value.jtype === JourneyType.SINGLE_CONSENT_WITH_PARAMS
    ) {
      //await getXDetails();

      return await api.getRequestDetails(requestId).then(async (f) => {
        await resolveConfigCatFeatures();
        store.updateFromRequestDetails(requestId, f);
        transitionToView(views.thankYou);
        isProcessing.value = false;
      });
      
    } else if (
      extraJourneyReq?.value.jtype === JourneyType.AUTHORIZED_MULTI_CONSENT
    ) {
      console.info("[mult-consent] Mode active (auth)");
      // [1] collect all child request identifiers, store the details (next used during consent grant flow)
      // [2] Use one of the request identifier to resolve required information
      const parentId = requestId;
      return await api
        .getChildRequestIds(parentId, store.extraJourneyReq?.receivedAuth)
        .then((childReqIds: string[]) => {
          console.info(
            `[mult-consent]-[${parentId}]: resolved sub-request identifiers ${childReqIds.toString()}`
          );
          store.preInit(requestId, childReqIds); // update the internal processIds
          const reqDetailsPromises = childReqIds.map((childId) => {
            return api
              .getRequestDetails(childId, store.extraJourneyReq?.receivedAuth)
              .then((reqDetails) => {
                return {
                  activeRequestId: childId,
                  data: reqDetails,
                };
              });
          });

          return Promise.all(reqDetailsPromises);
        })
        .then(async (responses) => {
          // !!! CODE DUPLICATION: start
          responses.forEach((res) => {
            console.info(
              `[mult-consent]-[${parentId}]: updating request details for {${res.activeRequestId}}`
            );
            store.updateFromRequestDetails(
              parentId,
              res.data,
              res.activeRequestId
            );
          });

          // use first response object data for filling/fetching required details
          const data = responses[0].data;
          const activeRequestId = responses[0].activeRequestId;
          await setJourneyFeatures(data);
          await setJourneyTemplates(data);
          await resolveConfigCatFeatures();
          if(store.extraJourneyReq?.receivedAuth){
            await fetchUserConsentedAccounts();
          }
          //fetchUserConsentedAccounts();
          lastKnownStatus.value = LastStatus.REQUIRES_FIP_SELECTION;
          // Decide one of two things
          // 1. Show bank-selection (if not filters are available)
          // 2. Directly move to AA-selection
          //encryptedParams.value = await updateAndGetWebviewParams(aaHandle.value);
          if (
            data &&
            (data.flowState === "SUCCESS")
          ) {
            const error = {
              errorCode: API_ERROR_RESPONSE_CODES.ALREADY_COMPLETED,
              errorMessage:
                data?.errorCode === ERROR_RESPONSE_CODES.ALREADY_COMPLETED
                  ? API_ERROR_RESPONSE_CODES.ALREADY_COMPLETED
                  : API_ERROR_RESPONSE_CODES.LINK_EXPIRED,
            };
            errorObject.value = error;
            handleAPIError(
              "Err, already processed!",
              "You're trying to visit a journey that is already completed or expired. If you feel this is in error, please reach out to Support.",
              "Invalid Request ID/URL provided"
            );
            eventEmitter.emit(InternalConductorEvent.JOURNEY_COMPLETED);
          }
          await getListOfFips(activeRequestId);
          if (data?.accountFilters && data?.accountFilters?.length) {
            // parentId here used for generating events, nothing else
            handleAvailableAccountFilters(data, parentId, view);
          } else {
            // parentId here used for generating events, nothing else
            handleUnavailableAccountFilters(data, parentId, view);
          }
          
        })
        .catch((e: any) => {
          console.log(e);
          log.fatal("Failed while initializing the journey", e?.message);
          if (e instanceof AxiosError) {
            const result = apiErrorHandler(e, events, requestId);
            if (result === true) {
              return; // return only if apiErrorHandler is showing modal to the user
            }
          }

          _fireExit(
            "UNKNOWN",
            "API_ERROR",
            "Unhandled API error, reach out to Finarkein support"
          );
        }).finally(()=>{
          isProcessing.value = false;
        });
      // !!! CODE DUPLICATION: end
    } else {
      // TODO: resolve these features from requestDetails#features --> server maintains these
      //store.setFeature(ConductorFeatures.ACCOUNTS_AUTO_SELECT, false);
      /*  if (useRoute().query && useRoute().query.auth) {
        receivedAuth.value = useRoute().query.auth;
      } */
      return await api
        .getRequestDetails(requestId, store.extraJourneyReq?.receivedAuth)
        .then(async (data) => {
          // IMPORTANT!
          store.updateFromRequestDetails(requestId, data);
          await setJourneyFeatures(data);
          await setJourneyTemplates(data);
          await resolveConfigCatFeatures();
          //await fetchUserConsentedAccounts();
          if(store.extraJourneyReq?.receivedAuth){
            await fetchUserConsentedAccounts();
          }
          lastKnownStatus.value = LastStatus.REQUIRES_FIP_SELECTION;
          // Decide one of two things
          // 1. Show bank-selection (if not filters are available)
          // 2. Directly move to AA-selection
          //encryptedParams.value = await updateAndGetWebviewParams(aaHandle.value);
         
          if (data && data.flowState === "FAILED") {
            const failedHandler = failedLayoutHandler[layoutView];
            failedHandler ? failedHandler(data) :  "" ;
            //handleFailedFlowState(data);
          } else if (data && data.flowState === "SUCCESS") {
            eventEmitter.emit(InternalConductorEvent.JOURNEY_COMPLETED);
            layoutView === 'v2' ? transitionToView(v2Views.thankYou): handleAPIError("Err, already processed!",
              "You're trying to visit a journey that is already completed or expired. If you feel this is in error, please reach out to Support.",
              "Invalid Request ID/URL provided",ErrorCodes.ALREADY_COMPLETED);
            return;
          }
          await getListOfFips(store.requestId);
          if (data?.accountFilters && data?.accountFilters?.length) {
            handleAvailableAccountFilters(data, requestId, view);
          } else {
            handleUnavailableAccountFilters(data, requestId, view);
          }
          isProcessing.value = false;

          // TODO: handle show bank selection screen

          //_fireExit("INVALID_INSTITUTION", "INPUT_ERROR", ErrorMessages.NO_INSTITUTION);
        })
        .catch((e: any) => {
          console.log(e);
          log.fatal("Failed while initializing the journey", e?.message);
          if (e instanceof AxiosError) {
            const result = apiErrorHandler(e, events, requestId);
            if (result === true) {
              return; // return only if apiErrorHandler is showing modal to the user
            }
          }

          _fireExit(
            "UNKNOWN",
            "API_ERROR",
            "Unhandled API error, reach out to Finarkein support"
          );
          isProcessing.value = false;
        });
    }
  };

  function handleFailedFlowState(data:any){
    const error = {
      errorCode: API_ERROR_RESPONSE_CODES.ALREADY_COMPLETED,
      errorMessage:
        data?.errorCode === ERROR_RESPONSE_CODES.ALREADY_COMPLETED
          ? API_ERROR_RESPONSE_CODES.ALREADY_COMPLETED
          : API_ERROR_RESPONSE_CODES.LINK_EXPIRED,
    };
    errorObject.value = error;
    handleAPIError(
      "Err, already processed!",
      "You're trying to visit a journey that is already completed or expired. If you feel this is in error, please reach out to Support.",
      "Invalid Request ID/URL provided",ErrorCodes.ALREADY_COMPLETED
    );
    eventEmitter.emit(InternalConductorEvent.JOURNEY_COMPLETED);
    return;
  }
  function transitionToView(view: any, layout = JOURNEY_LAYOUTS.V2) {
    if (view) {
      if (view.comp) {
        // Don't tranisiton for duplicate calls! and log!
        if (currentView.value === view.name) {
          log.error(
            `[NEED REFACTOR]: Duplicate transition requested [${view.name}]`
          );
          return;
        }

        previousHandler.value = currentView.value;
        viewHandler.value = view.comp;
        currentView.value = view.name;

        // separate the view related features heree
        /*  const viewTemplates = filterTemplates("view", store.features);
        store.viewsType = utils.splitTemplateString(viewTemplates, 3, 4); */
        events.fire(
          ConductorEvent.TRANSITION_VIEW,
          eventPayload(store.requestId, discriminator.value, {
            name: view.name,
            layout: layoutView ? layoutView : layout,
          })
        );
      }
    }
  }

  // ==================
  // Consent Detail
  // =================

  // function consentDetail(requestId: string) {
  //   useBackend().getRequestDetails(requestId)
  //   .then((response: any) => {
  //     console.log(response.webviewConsentTemplate.def);
  //   })
  // }

  // ==================
  // AA LOGIN stuff : start
  // =================

  async function prepareAccountAggregator() {
    // try and use the filters and/or stored selected FIP(s)
    lastKnownStatus.value = LastStatus.REQUIRES_AA_OTP;
    // trigger the next screen process
    await updateAndGetWebviewParams(aaHandle.value);
    if (
      customAAJourneyList.value &&
      !customAAJourneyList.value.includes(aaHandle.value) &&
      layoutView === JOURNEY_LAYOUTS.V2 &&
      webRedirectUrl.value
    ) {
      window.location.href = webRedirectUrl.value;
      //window.open(webRedirectUrl.value);
    } else {
      awaitNext.value = _initForAaLogin();
    }
  }

  const apiErrorHandler = (error: AxiosError, events: any, reqId: string) => {
    //transitionToView(views.errorPage);
    if (error.code == "ERR_NETWORK") {
      eventEmitter.emit(InternalConductorEvent.NETWORK_ERROR);
      events.fire(
        ConductorEvent.ERROR,
        eventPayload(reqId, discriminator.value, {
          errorCode: ErrorCodes.NO_NETWORK,
          errorMessage: error.message,
          errorType: ErrorTypes.NET_ERROR,
        })
      );

      const errValue = {
        errorCode: ERROR_RESPONSE_CODES.LINK_EXPIRED,
        errorMessage:
          "You're trying to visit a journey that is already completed or expired. If you feel this is in error, please reach out to Support.",
      };
      errorObject.value = errValue;
      transitionToView(views.errorPage);
    
   
    } else if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      // console.log(error.response.data);
      // console.log(error.response.status);
      // console.log(error.response.headers);

      // Known API status codes handling
      const data: any = error.response.data;
      if (error.response.status === 409) {
        events.fire(ConductorEvent.API_ERROR,eventPayload(reqId, discriminator.value, {
          errorCode: data?.errorCode === ERROR_RESPONSE_CODES.ALREADY_COMPLETED ?ErrorCodes.ALREADY_COMPLETED : ErrorCodes.LINK_EXPIRED,
          errorMessage: "Journey is already completed",
        }));
        if (
          data?.errorCode === ERROR_RESPONSE_CODES.ALREADY_COMPLETED ||
          data?.errorCode === ERROR_RESPONSE_CODES.LINK_EXPIRED
        ) {
          eventEmitter.emit(InternalConductorEvent.JOURNEY_COMPLETED);
          const error = {
            errorCode: data?.errorCode,
            errorMessage:
              data?.errorCode === ERROR_RESPONSE_CODES.ALREADY_COMPLETED
                ? API_ERROR_RESPONSE_CODES.ALREADY_COMPLETED
                : API_ERROR_RESPONSE_CODES.LINK_EXPIRED,
          };
          errorObject.value = error;
          if (layoutView == JOURNEY_LAYOUTS.V5) {
            handleAPIError(
              "Err, already processed!",
              "You're trying to visit a journey that is already completed or expired. If you feel this is in error, please reach out to Support.",
              "Invalid Request ID/URL provided"
            );
          } else {
            handleAPIError(
              "Err, already processed!",
              "You're trying to visit a journey that is already completed or expired. If you feel this is in error, please reach out to Support.",
              "Invalid Request ID/URL provided"
            );
           
          }

          return true;
        }
      }
      // if the request id is invalid
      if (error.response.status === 404) {
        if (data?.errorCode === ERROR_RESPONSE_CODES.DATA_NOT_FOUND) {
          if (layoutView != JOURNEY_LAYOUTS.V5) {
            handleAPIError(
              "Sorry Could not fetch details",
              "Please try other methods to upload bank statement",
              "Invalid Request ID/URL provided"
            );

            eventEmitter.emit(InternalConductorEvent.INVALID_URL);
            return true;
          } else {
            eventEmitter.emit(InternalConductorEvent.INVALID_URL);

          }
        }
      }
      //any internal error
      if (error.response.status === 500) {
        if (
          data?.errorCode === ERROR_RESPONSE_CODES.IN_PROCESS ||
          data?.errorCode === ERROR_RESPONSE_CODES.INTERNAL_ERROR
        ) {
          const error = {
            errorCode: data?.errorCode,
            errorMessage:
              data?.errorCode === ERROR_RESPONSE_CODES.IN_PROCESS
                ? API_ERROR_RESPONSE_CODES.IN_PROCESS
                : API_ERROR_RESPONSE_CODES.INTERNAL_ERROR,
          };
          errorObject.value = error;
          handleAPIError(
            "Sorry Could not fetch details",
            "Please try other methods to upload bank statement",
            "Service Unavailable.",
            ErrorCodes.ALTERNATIVE_CHOSEN,
            ErrorTypes.FLOW_ERROR
          );
          return true;
        }
      }

      if(error.response.status===400){
        handleBadRequest(ErrorCodes.BAD_REQUEST,ErrorTypes.API_ERROR,error.message,false);
        return true;
      }


      events.fire(
        ConductorEvent.ERROR,
        eventPayload(reqId, discriminator.value, {
          errorCode: error.response.status.toString(),
          errorMessage: error.message,
          errorType: ErrorTypes.API_ERROR,
        })
      );
    } else if (error.request) {

      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      // console.log(error.request);
    } else {

      // Something happened in setting up the request that triggered an Error
      // console.log('Error', error.message);
    }
  };

  function invokeTroubleshoot(ctx: any, resetData = false) {
    // modify the onPrimary call handler to add state clean as well
    const _primaryHandler = ctx.onPrimary;
    const _secondaryHandler = ctx.onSecondary;

    ctx.onPrimary = () => {
      // now invoke the supplied primary handler
      if (_primaryHandler) {
        // ensure handler is present
        _primaryHandler();
      }

      if (resetData) {
        troubleshootError.value = undefined;
      }
    };

    ctx.onSecondary = () => {
      // now invoke the supplied secondary handler
      if (_secondaryHandler) {
        // ensure handler is present
        _secondaryHandler();
      }
    };

    troubleshootError.value = ctx;
  }

  async function resendAaOTP() {
    //reset store to get updated valuee
    store.otpReference = undefined;
    return await _sendOtpWithAA(true);
  }

  function aaErrorContext(response: any) {
    const context = {} as any;
    if (
      response?.aaOperation ||
      response?.data?.aaOperation ||
      response.reason?.data?.aaOperation
    ) {
      context.aaOperation =
        response?.aaOperation ||
        response.data?.aaOperation ||
        response.reason?.data?.aaOperation;
    }
    if (
      response?.aaOpAttempt ||
      response?.data?.aaOpAttempt ||
      response?.reason?.data?.aaOpAttempt
    ) {
      context.aaOpAttempt =
        response?.aaOpAttempt ||
        response?.data?.aaOpAttempt ||
        response?.reason?.data?.aaOpAttempt;
    }
    if (
      response?.aaErrorCode ||
      response?.data?.aaErrorCode ||
      response?.reason?.data?.aaErrorCode
    ) {
      context.aaErrorCode =
        response?.aaErrorCode ||
        response?.data?.aaErrorCode ||
        response?.reason?.data?.aaErrorCode;
    }
    return context;
  }

  async function _sendOtpWithAA(isRetry = false) {
    // At this stage, SDK Adapter must have been initialized, else this is way way wrong!
    aaSdk.value = aaSdkAdapter.value.getSdk(aaHandle.value)!!;
    aaSdk.value.cleanUp();

    // If journey type MULTI CONSENT, then we need to initiate login with only mobile
    // const authInitInput: Partial<AuthInitRequest> =
    //   store.extraJourneyReq.jtype === JourneyType.AUTHORIZED_MULTI_CONSENT
    //     ? {
    //         mobileNum: store.customer.mobile,
    //       }
    //     : (encryptedParams.value as AuthInitRequest);

    const authInitInput: Partial<AuthInitRequest> =
      encryptedParams.value as AuthInitRequest;
    if (store.customer.mobile && aaHandle.value !== "finvu") {
      authInitInput.mobileNum = store.customer.mobile;
    }
    flaggedEvents( isRetry ? ConductorEvent.PRE_AA_OTP_RESENT:ConductorEvent.PRE_AA_OTP_SENT,eventPayload(store.requestId, discriminator.value, {
      aaHandle: aaHandle.value,
    }), { internal: true })

    return aaSdk.value
      ?.authInit(authInitInput)
      .then(async (response: any) => {
        const validateWrapped = operationWrapper.getModifiedResponse(
          response,
          aaHandle.value,
          "VALIDATE_USER"
        );
        // aaError.value = false;
        if (validateWrapped?.status === "SUCCESS") {
          // SEND - Request ok, otp has been sent, send OTP
          otpReference.value = validateWrapped.data.otpReference;
          log.silly(
            `${isRetry ? "Re-s" : "S"}ent OTP from Account Aggregator`,
            aaHandle.value
          );
          events.fire(
            isRetry ? ConductorEvent.AA_OTP_RESENT : ConductorEvent.AA_OTP_SENT,
            eventPayload(store.requestId, discriminator.value, {
              aaHandle: aaHandle.value,
            })
          );
          showOtpSentPopup.value = true;

          // Hide the popup after 5 seconds
            setTimeout(() => {
              showOtpSentPopup.value = false;
            }, 5000);
        } else if (validateWrapped?.status === "RETRY") {
          log.debug(
            `Recoverable error while ${
              isRetry ? "Re-" : ""
            }sending OTP from Account Aggregator`,
            aaHandle.value
          );
          events.fire(
            ConductorEvent.AA_OTP_FAILED,
            eventPayload(
              store.requestId,
              discriminator.value,
              {
                aaHandle: aaHandle.value,
                retriable: true,
              },
              aaErrorContext(validateWrapped)
            )
          );
        } else if (validateWrapped?.status === "BAD_REQUEST") {
          events.fire(
            ConductorEvent.AA_OTP_FAILED,
            eventPayload(
              store.requestId,
              discriminator.value,
              {
                aaHandle: aaHandle.value,
                retriable: false,
                /*  message: validateWrapped.message,
             status: validateWrapped.status */
              },
              aaErrorContext(validateWrapped)
            )
          );
          handleBadRequest(
            ErrorCodes.BAD_REQUEST,
            ErrorTypes.INPUT_ERROR,
            validateWrapped.message,
            true,
            aaErrorContext(validateWrapped)
          );
        } else if (validateWrapped?.status === "FAILURE") {
          //TODO : Switch AA
          events.fire(
            ConductorEvent.AA_OTP_FAILED,
            eventPayload(
              store.requestId,
              discriminator.value,
              {
                aaHandle: aaHandle.value,
                retriable: false,
                /*  message: validateWrapped.message,
             status: validateWrapped.status */
              },
              aaErrorContext(validateWrapped)
            )
          );
          handleFailures(
            ErrorCodes.FAILURE,
            ErrorTypes.FLOW_ERROR,
            validateWrapped.message,
            true,
            aaErrorContext(validateWrapped)
          );
        } else if (validateWrapped?.status === "SESSION_ERROR") {
          handleSessionError();
        } else if (validateWrapped?.status === "UNKNOWN_ERROR") {
          handleFailures(
            ErrorCodes.UNKNOWN,
            ErrorTypes.FLOW_ERROR,
            validateWrapped.message,
            true,
            aaErrorContext(validateWrapped)
          );
        }
      })
      .catch((e: any) => {
        // TODO: handle AA interaction error here
        // maybe inform the caller that we could not talk to AA here?
        if (e === ErrorMessages.SESSION_EXPIRED) {
          handleSessionError();
        } else {
          _raiseErrorEventForAA(
            "Failed to connect to Account Aggregator",
            "AA_UNREACHABLE"
          );
        }
        log.fatal(
          "Failed to " +
            (isRetry ? "Re-" : "") +
            "send OTP, some error occurred due to session or request timeout"
        );
      })
      .finally(()=>{
        flaggedEvents(isRetry ? ConductorEvent.POST_AA_OTP_RESENT : ConductorEvent.POST_AA_OTP_SENT,eventPayload(store.requestId, discriminator.value, {
          aaHandle: aaHandle.value,
        }), { internal: true } )
      })
  }

  async function _initForAaLogin() {
    // Initialize based on selected account aggregator
    await aaSdkAdapter.value.setUpSdk(aaHandle.value);
    log.silly("Initiating OTP from Account Aggregator", aaHandle.value);
    return await _sendOtpWithAA();
  }

  function _raiseErrorEventForAA(
    errorMessage: string,
    errorCode = "AA_ERROR",
    errorType = "INSTITUTION_ERROR",
    extraParams: any = {}
  ) {
    events.fire(
      ConductorEvent.ERROR,
      eventPayload(store.requestId, discriminator.value, {
        errorCode,
        errorType,
        errorMessage,
        ...extraParams,
      })
    );
  }

  async function updateAndGetWebviewParams(aaHandle: string) {
    let isError = false;
    const requestIds = store.processIds ?? [xStore.reqId];
    isProcessing.value = true;
    let routeTo;
    if (layoutView === JOURNEY_LAYOUTS.V2) {
      routeTo = "v2u.landing";
    }
    const finalRouteTo = routeTo;

   const promises = requestIds.map((id) =>
    api
      .getWebviewDetails(
        id,
        { aaHandle },
        tenantId.value,
        finalRouteTo,
        extraJourneyReq.value?.receivedAuth
      )
      .then((res) => ({
        id: id,
        status: 'fulfilled',
        data: res.data,
      })).finally(()=>{
        isProcessing.value = false;
      })
  );

    const responses = await Promise.allSettled(promises) as any;

    const successfulResponses = responses.filter((response: { status: string; }) => response.status === 'fulfilled');
    const failedResponses = responses.filter((response: { status: string; }) => response.status === 'rejected');  
    log.silly("Successfully resolved request details");
    if(successfulResponses.length>0){
      webRedirectUrl.value = successfulResponses[0].value.data.redirectUrl; // backward compatibility
    }
    let lastEncryptedParams;
    successfulResponses.forEach((response:any) => {
      // overwrite: backward compatibility
      lastEncryptedParams = utils.getEncrypedParams(response.value.data.redirectUrl);
      store.updateWebViewParams(lastEncryptedParams, response.value.id);
      store.updateConsentHandle(response.value.data.consentHandle, response.value.id);
    });
  
    if (failedResponses.length > 0) {
      log.error("Some requests failed while getting webview request details:");
      failedResponses.forEach((response:any) => {
        isError = true;
        if (response.reason instanceof AxiosError) {
          apiErrorHandler(response.reason, events, store.requestId);
        }
      });
      return;
    }
  
    return lastEncryptedParams;
  }

  function verifyAaOTPAndNext(otpFromUser: string, startAuto = false) {
    flaggedEvents(ConductorEvent.PRE_AA_OTP_VERIFY, eventPayload(store.requestId, discriminator.value, {
      internal: true
    }))
    return new Promise<void>((resolve, reject) => {
      aaSdk.value
        ?.authVerify({
          otp: otpFromUser,
          otpReference: otpReference.value!,
        })
        .then(async (response: any) => {
          const loginWrapped = operationWrapper.getModifiedResponse(
            response,
            aaHandle.value,
            "AA_LOGIN"
          );
          if (loginWrapped?.status === "SUCCESS") {
            fireJourneyEvents(ConductorEvent.AA_OTP_VALID);
            aaAuth.value = true;
            // TODO: prepare for next screen to show
            lastKnownStatus.value = LastStatus.REQUIRES_LINKED_ACCOUNTS;
            if (
              layoutView !== JOURNEY_LAYOUTS.V6 &&
              layoutView !== JOURNEY_LAYOUTS.V7 &&
              layoutView !== JOURNEY_LAYOUTS.V8
            ) {
              prepareAccountsAndDiscoveryLinking();
              //transitionToView(views.discovery);
            }
            // viewHandler.value = components.discovery;

            log.silly("Verified AA OTP successfully");
            resolve(); // update the otp verification status
          } else if (loginWrapped?.status === "RETRY") {
            fireJourneyEvents(
              ConductorEvent.AA_OTP_INVALID,
              {},
              {},
              aaErrorContext(loginWrapped)
            );
            log.warn("Please retry again");
            reject("RETRY");
          } else if (loginWrapped?.status === "FAILURE") {
            fireJourneyEvents(
              ConductorEvent.AA_OTP_INVALID,
              {},
              {},
              aaErrorContext(loginWrapped)
            );
            handleFailures(
              ErrorCodes.FAILURE,
              ErrorTypes.FLOW_ERROR,
              ErrorMessages.SOME_UNKOWN_ERROR,
              true,
              aaErrorContext(loginWrapped)
            );
            /*  log.warn("Please retry again");
           reject("RETRY"); */
          } else if (loginWrapped?.status === "BAD_REQUEST") {
            fireJourneyEvents(
              ConductorEvent.AA_OTP_INVALID,
              {},
              {},
              aaErrorContext(loginWrapped)
            );
            handleBadRequest(
              ErrorCodes.BAD_REQUEST,
              ErrorTypes.FLOW_ERROR,
              ErrorMessages.BAD_REQUEST,
              true,
              aaErrorContext(loginWrapped)
            );
            /*  log.warn("Please retry again");
           reject("RETRY"); */
          } else if (loginWrapped?.status === "UNKNOWN_ERROR") {
            handleFailures(
              ErrorCodes.UNKNOWN,
              ErrorTypes.FLOW_ERROR,
              loginWrapped.message,
              true,
              aaErrorContext(loginWrapped)
            );
          } else {
            // TODO: handle AA OTP verification error
            fireJourneyEvents(
              ConductorEvent.AA_OTP_VERIFY_FAILED,
              {},
              {},
              aaErrorContext(loginWrapped)
            );
            log.error(
              "AA OTP verifification failed, with AA message?",
              response?.message
            );
            reject();
          }
        })
        .catch((e: any) => {
          // TODO: handle AA interaction error here
          // maybe inform the caller that we could not talk to AA here?
          fireJourneyEvents(ConductorEvent.AA_OTP_VERIFY_FAILED);
          log.fatal("AA OTP verifification failed", e | e?.message);
          if (e === ErrorMessages.SESSION_EXPIRED) {
            handleSessionError();
            return;
          }
          reject();
        }).finally(() => {
          flaggedEvents(ConductorEvent.POST_AA_OTP_VERIFY, eventPayload(store.requestId, discriminator.value, {
            internal: true
          }))
        })
    });
  }

  async function prepareAccountsAndDiscoveryLinking() {
    isProcessing.value = true; // Completes at the end!
    // 0. Fetch and update user details, need mobile number to be available for account discovery to work
    log.silly(
      "Fetching consent request details with consent handle:",
      store.consentHandle
    );
    await aaSdk
      .value!.consentRequestDetails(store.consentHandle!) // should never be null here
      .then((response: any) => {
        const consentDetailsWrapped = operationWrapper.getModifiedResponse(
          response,
          aaHandle.value,
          "CONSENT_DETAILS"
        );
        if (consentDetailsWrapped?.status === "SUCCESS") {
          // store them response first
          log.silly(
            "Successully resolved consent details with consent handle:",
            store.consentHandle
          );
          store.updateConsentRequestInfo(consentDetailsWrapped.data);
        } else {
          // TODO: handle non-success scenario for getting linked accounts.
          handleBadRequest(
            ErrorCodes.FAILURE,
            ErrorTypes.FLOW_ERROR,
            "FAILURE",
            true,
            aaErrorContext(consentDetailsWrapped)
          );
          // invoke troubleshoot helper
          //invokeTroubleshoot(ctx);
          log.debug(
            "Failed to get consent details with consent handle:",
            store.consentHandle
          );
        }
      })
      .catch((e: any) => {
        // TODO: handle AA interaction error here
        // maybe inform the caller that we could not talk to AA here?
        if (e === ErrorMessages.SESSION_EXPIRED) {
          handleSessionError();
        }
        log.fatal(
          "Error while trying to get consent details with consent handle:",
          store.consentHandle,
          e
        );
      });

    log.silly("Fetching user details from AA");
    await aaSdk
      .value!.getUserInfo()
      .then((response: any) => {
        const userInfoWrapped = operationWrapper.getModifiedResponse(
          response,
          aaHandle.value,
          "USER_INFO"
        );
        if (userInfoWrapped?.status === "SUCCESS") {
          // store the response first
          log.silly("Got the user details from AA");
          store.updateUserInformation(userInfoWrapped.data.UserInfo);
        } else if (userInfoWrapped?.status === "BAD_REQUEST") {
          handleBadRequest(
            ErrorCodes.DATA_INVALID,
            ErrorTypes.INPUT_ERROR,
            userInfoWrapped.message,
            true,
            aaErrorContext(userInfoWrapped)
          );
        } else {
          // TODO: handle non-success scenario for getting linked accounts.
          log.debug("Failed to get the user details from AA");
          handleBadRequest(
            ErrorCodes.FAILURE,
            ErrorTypes.FLOW_ERROR,
            "FAILURE",
            true,
            aaErrorContext(userInfoWrapped)
          );
        }
      })
      .catch((e: any) => {
        // TODO: handle AA interaction error here
        // maybe inform the caller that we could not talk to AA here?
        if (e === ErrorMessages.SESSION_EXPIRED) {
          handleSessionError();
        }
        log.fatal("Error while trying to get user details from AA", e);
      });

    log.silly(
      "Fetching available financial institutions for requestId:",
      store.requestId
    );
    // 1. Fetch linked accounts
    // 2. Check if selected FIP accounts are present
    // 3. Trigger discovery for missing FIP accounts
    //  3.1 Retry discovery a few times if fails
    awaitNext.value = Promise.resolve()
      .then(() => {
        log.silly("Fetching already linked accounts from AA");
        //just to check whether we get user linked acc or not
        //sessionStorage.removeItem(FinvuConstants.UID);
        lastKnownStatus.value = LastStatus.REQUIRES_LINKED_ACCOUNTS;
        return aaSdk
          .value!.getLinkedAccounts()
          .then(async (response: any) => {
            const linkedAccWrapped = operationWrapper.getModifiedResponse(
              response,
              aaHandle.value,
              "LINKED_ACCOUNTS"
            );
            if (linkedAccWrapped?.status === "SUCCESS") {
              if (linkedAccWrapped.data.LinkedAccounts.length > 0) {
                // store them first
                const linkedAccounts = linkedAccWrapped.data.LinkedAccounts;
                const filteredLinkedAccounts =
                  store.financialInstruments.length > 0
                    ? getFilteredAccounts(linkedAccounts)
                    : linkedAccounts;
                log.silly(
                  "Fetched already linked accounts from AA, there are ",
                  linkedAccounts?.length > 0 ? "a few" : "none",
                  "linked"
                );
                store.updateLinkedAccounts(filteredLinkedAccounts);
                //store.updateLinkedAccounts(linkedAccounts);

                if (filteredLinkedAccounts.length > 0) {
                  fireJourneyEvents(ConductorEvent.LINKED_ACCOUNTS_FETCHED);
                  if (layoutView === JOURNEY_LAYOUTS.V2) {
                    if(relevantLinkedAccounts(filteredLinkedAccounts)) {
                      transitionToView(views.consentPage);
                    }else{
                      transitionToView(views.discovery);
                    }
                    
                  } else {
                    transitionToView(views.discovery); // for navi its missing
                  }
                } else {
                  fireJourneyEvents(
                    ConductorEvent.NO_LINKED_ACCOUNTS,
                    {},
                    {},
                    {
                      hasLinked: true, // Update this flag to indicate that there were linked accounts but none are matching current FI types
                    }
                  );
                  transitionToView(views.discovery); // for navi its missing
                }
                // TEMP: FIX LATER
                if(layoutView ===JOURNEY_LAYOUTS.V2 && relevantLinkedAccounts(filteredLinkedAccounts)){
                  return new Set();
                }else{
                  return updateAndGetDiscoveryQueue(linkedAccounts);
                }
               
              } else {
                // TODO: handle non-success scenario for getting linked accounts
                log.debug("Failed(?) to get already linked accounts from AA");
                fireJourneyEvents(ConductorEvent.NO_LINKED_ACCOUNTS);
                transitionToView(views.discovery);
                // if (layoutView === JOURNEY_LAYOUTS.V2) {
                //   transitionToView(views.discovery);
                // }
                return updateAndGetDiscoveryQueue();
              }
            } else {
              // Linked account fetch has failed probably!
              log.debug("Failed to get already linked accounts from AA");
              fireJourneyEvents(
                ConductorEvent.ERROR,
                {}, // request details, if any
                {
                  // general error information
                  errorCode: ErrorCodes.FAILURE,
                  errorType: ErrorTypes.FLOW_ERROR,
                  errorMessage: "Failed to resolve linked accounts",
                },
                aaErrorContext(linkedAccWrapped), // aa error context
                { internal: true } // labels
              );
              if (layoutView === JOURNEY_LAYOUTS.V2) {
                transitionToView(views.discovery);
              }
              return updateAndGetDiscoveryQueue();
            }
          })
          .catch((e: any) => {
            // TODO: handle AA interaction error here
            // maybe inform the caller that we could not talk to AA here?
            if (e === ErrorMessages.SESSION_EXPIRED) {
              handleSessionError();
              return new Set<string>();
            }
            log.error(
              "Error while trying to get already linked accounts from AA",
              e
            );
            return updateAndGetDiscoveryQueue();
          });
      })
      .then(async (discoveryQue: Set<string>) => {
        lastKnownStatus.value = LastStatus.REQUIRES_ACCOUNTS_DISCOVERY;
        const institutions = store.getInstitutions();
        const financialInstruments = store.financialInstruments;
        let result = Promise.resolve();
        const extraIdentifiersNeeded:any = [];
        const identifierTypes: any = new Set();
        discoveryQue.forEach((disc) => {
          const element = institutions.value?.get(disc) || '';
          if (element && layoutView === JOURNEY_LAYOUTS.V2 && isInfoRequired(element)) {
            const identifiers = typeOfInfoRequired(element);
        
            identifiers.forEach((identifier) => {
              const type = identifier.type;
              if (type !== undefined && !identifierTypes.has(type)) {
                identifierTypes.add(type);
                extraIdentifiersNeeded.push(identifier);
              } 
            });
          } 
        });
        
        extraIdentifiersList.value = extraIdentifiersNeeded;
        askAdditionalInfo.value = true;
        if (discoveryQue.size > 0) {
          return new Promise<any>((resolve, reject) => {
            isProcessing.value = true;
            let overallSuccess = false;
            const errors: any[] = [];
            const badRequests: any[] = [];

            const processQueue = async () => {
              for (const id of discoveryQue) {
                console.log("Processing institution with id", id);
                const institution = institutions.value?.get(id);
                if (!institution) {
                  log.error(
                    `Institution '${id}' in queue for discovery, but it's unavailable at the moment`
                  );
                  errors.push({
                    institution: id,
                    error: "Institution unavailable",
                  });
                  continue;
                }

                let institutionProcessed = false;
                let retryCount = 0;
                const maxRetries = 3; //retry option

                while (!institutionProcessed && retryCount < maxRetries) {
                  try {
                    store.updateDiscoveryStatus(
                      {
                        discovering: true,
                        auto: false,
                      },
                      institution
                    );

                    const rawResult = (await doAccountDiscovery(
                      store.customer.mobile!,
                      institution,
                      financialInstruments,
                      0,
                      retryCount,
                      undefined,
                      false
                    )) as any;

                    if (rawResult.status === "ADDITIONAL_INFO_REQUIRED") {
                      console.log("Additional info required now:",id);
                      await new Promise<void>((resolveInfo) => {
                        doAccDiscoveryCallback = async () => {
                          console.log("Additional info received now",id
                          );
                          resolveInfo();
                        };
                        askAdditionalInfo.value = true;
                      });
                      askAdditionalInfo.value = false;
                      retryCount++;
                      continue;
                    }

                    const result = (await processDiscoveryResult(
                      rawResult,
                      false,
                      0
                    )) as any;

                    if (result.sessionError) {
                      console.error(`Session error for institution: ${id}`);
                      errors.push({ institution: id, error: "Session error" });
                      break;
                    }

                    if (result.success) {
                      overallSuccess = true;
                      institutionProcessed = true;
                    } else if (result?.badRequest) {
                      badRequests.push(result.badRequest);
                      institutionProcessed = true;
                    } else if (result.error) {
                      errors.push(result.error);
                      institutionProcessed = true;
                    }
                  } catch (error: any) {
                    console.log('error Error processing institution', error);
                    console.error(`Error processing institution ${id}:`, error);
                    errors.push({
                      institution: id,
                      error: error.message || "Unknown error",
                    });
                    institutionProcessed = true;
                  } finally {
                    store.updateDiscoveryStatus(
                      {
                        status:DiscoveryStatus.FAILED,
                        discovering: false,
                        auto: false,
                      },
                      institution
                    );
                  }

                  retryCount++;
                }

                console.log("Completed processing institution", id);
              }
            };

            processQueue()
              .then(() => {
                resolve({ success: overallSuccess, errors, badRequests });
              })
              .catch((error) => {
                console.error(
                  "An unexpected error occurred during queue processing",
                  error
                );
                handleFailures(
                  ErrorCodes.UNKNOWN,
                  ErrorTypes.FLOW_ERROR,
                  "An unexpected error occurred during queue processing",
                  true,
                  { error }
                );
                reject({
                  success: false,
                  errors: [...errors, error],
                  badRequests,
                });
              })
              .finally(() => {
                isProcessing.value = false;
              });
          });
        }
        else if (
          (discoveryQue.size === 0 && layoutView !== JOURNEY_LAYOUTS.V2) ||
          (layoutView === JOURNEY_LAYOUTS.V5)
        ) {
          // TEMP FIX:here we do not want to allow discovery if reelvant accounts are present for v2
          const isAutoDiscoveryEnabled = store.getFeature(
            ConductorFeatures.ACCOUNTS_AUTO_DISCOVERY,
            true
          );
          if (isAutoDiscoveryEnabled) {
            result = result.then(async () => {
              fireJourneyEvents(
                ConductorEvent.AUTO_DISCOVERY_MODE,
                {},
                {},
                { userInitiated: false }
              );
              autoDiscoveryCount.value += 1;
              await autoDiscovery();
            });
          }
        }
      });
      /* .finally(() => {
        // mark the loading false
        console.log('from que and false')
        isProcessing.value = false;
      }); */
      
  }
  let doAccDiscoveryCallback: () => void; // temp fix: refactor later

  async function doAccountDiscovery(
    mobile: string,
    institution: Institution,
    instruments: string[],
    idSeq: number,
    retry = 0,
    limit = 3,
    auto = false
  ) {
    log.silly(
      "Discovering accounts from AA for",
      institution.id,
      retry > 0 ? "retry (x" + retry + ")" : ""
    );
  
    if (retry >= limit) {
      return { status: 'MAX_RETRY_REACHED', institution };
    }
  
    if (layoutView === JOURNEY_LAYOUTS.V2 && isInfoRequired(institution)) {
      // Set up the callback for when additional info is provided
      doAccDiscoveryCallback = async () => {
        const result = await doAccountDiscovery(mobile, institution, instruments, idSeq, retry, limit, auto);
        const resultHere = await processDiscoveryResult(result, auto, idSeq);
        return resultHere;
      };
      extraIdentifiersList.value = typeOfInfoRequired(institution); 
      askAdditionalInfo.value = true;
      // Return early to indicate additional info is needed
      return { status: 'ADDITIONAL_INFO_REQUIRED', institution };
    }
    askAdditionalInfo.value = false;
  
    if (!askAdditionalInfo.value) {
      // Prepare the discovery request
      const request = {
        FIPDetails: {
          fipId:institution.id,
          fipName: institution.name,
        },
        FITypes: instruments,
        Customer: {
          Identifiers: [
            {
              category: "STRONG",
              type: "MOBILE",
              value: mobile,
            },
          ],
        },
      };
  
      const askDetails = store.getFeature(
        ConductorFeatures.ASK_USER_DETAILS,
        false
      );



      if (askDetails || !isInfoRequired(institution)) {
        const extraUserInfo = getRequiredIdentifiers(institution);
        request.Customer.Identifiers.push(...extraUserInfo);
      }


  
      try {
        const sts = moment();
        const response = await aaSdk.value?.discoverAccounts(request);
        const latency = moment.duration(moment().diff(sts)).asMilliseconds();
        return { 
          rawResponse: response, 
          institution, 
          latency, 
          request 
        };
  
      } catch (e: any) {
        if (e === ErrorMessages.SESSION_EXPIRED) {
          handleSessionError();
          return { status: 'SESSION_ERROR', institution };
        }
        return { status: 'ERROR', error: e, institution };
      }
    }
  }




  function updateAndGetDiscoveryQueue(linkedAccounts: LinkedAccount[] = []) {
    log.silly("Evaluating institutions for account discovery");
    //const toDiscover = new Set<string>(filterParser.value?.getInstitutions() as Set<string>);
    let toDiscover = filterKnownFips(
      Array.from(store.getInstitutions().value.values()),
      filterParser.value?.getInstitutions() as Set<string>
    );
    log.silly(
      "Requested institutions for account discovery",
      Array.from(toDiscover)
    );
    // to support fip selction
    if (layoutView === JOURNEY_LAYOUTS.V2) {
      if (
        Array.from(toDiscover).length == 0 &&
        store.selectedFipList.length > 0
      ) {
        toDiscover = new Set(
          store.selectedFipList.map((fip: { id: any }) => {
            return fip.id ? fip.id : fip;
          })
        );
      }
    }
    // to support fip selction
    if (
      layoutView === JOURNEY_LAYOUTS.V6 ||
      layoutView === JOURNEY_LAYOUTS.V8
    ) {
      if (
        Array.from(toDiscover).length == 0 &&
        store.selectedFipList.length > 0
      ) {
        /* toDiscover = new Set(store.selectedFipList.map((fip: { id: any; })=>{return fip.id})); */
        toDiscover = new Set(store.selectedFipList);
      }
    }

    const alreadyLinked = linkedAccounts.reduce(
      (result, current) => result.add(current.fipId),
      new Set<string>()
    );
    log.silly("Already linked institutions", Array.from(alreadyLinked));
    // Remove already linked accounts from discovery queue
    // alreadyLinked.forEach((value) => toDiscover.delete(value));
    store.updateInstitutionDiscoveryQueue(Array.from(toDiscover));
    log.silly(
      "Institution(s) eligible for account discovery",
      Array.from(toDiscover)
    );
    return toDiscover;
  }

  function filterKnownFips(
    fipList: Institution[],
    accountFilters: Iterable<any> | ArrayLike<any>
  ) {
    if (accountFilters) {
      const filteredFips = fipList.filter((instituition: Institution) => {
        return Array.from(accountFilters).some((account) => {
          return (
            account === instituition.id &&
            instituition.aa.length > 0 &&
            instituition.aa.includes(aaHandle.value)
          );
        });
      });
      return filteredFips.length > 0
        ? new Set(filteredFips.map((data: { id: any }) => data.id))
        : new Set([]);
    } else {
      return new Set([]);
    }
  }

  async function handleConsentAction(
    action: ConsentAction,
    selectedAccounts: FinancialAccount[],
    exitWithConsentReject = false,
    retryCount = 0,
    retryLimit = 3
  ) {
    consentAction.value = action;
    const relevantAccountsMap =
      prepareAccountsForConsentAction(selectedAccounts);
    const isMultiConsent =
      extraJourneyReq.value?.jType === JourneyType.AUTHORIZED_MULTI_CONSENT;

    const relevantConsents = new Map(
      Array.from(relevantAccountsMap.entries()).filter(
        ([consentHandleValue, accountslist]) =>
          (isMultiConsent && !exitWithConsentReject
            ? accountslist.length > 0
            : true) || exitWithConsentReject
      )
    );

    const results = await Promise.allSettled(
      Array.from(relevantConsents.entries()).map(
        async ([consentHandleValue, accountslist], index) => {
          const consentReq = consentHandleValue; // actual value
          // [NEW] check if mapped request id is enabled for the journey
          const currRequestId = utils.getKeyFromValue(store.consentHandleMap, consentHandleValue) as string;
          let rejectIfDisabled = false;
          let resolvedConsentAction = action;
          if (action === ConsentAction.ACCEPT && currRequestId && !store.isRequestEnabled(currRequestId)) {
            rejectIfDisabled = true;
            resolvedConsentAction = ConsentAction.DENY;
            const payload = eventPayload(store.requestId, discriminator.value, {
              consentAction: action,
              errorCode: "USER_DESELECTED",
              errorType: ErrorTypes.FLOW_ERROR,
              errorMessage: "Consent rejected due to user deselection of the consent request",
              exitStatus: lastKnownStatus.value || LastStatus.UNKNOWN, // share the last known status in the journey
              childRequestId: extraJourneyReq.value?.jType === JourneyType.AUTHORIZED_MULTI_CONSENT ? 
                currRequestId : undefined
            });

            events.fire(ConductorEvent.ERROR, payload);
          }

          const request = {
            consentHandleId: consentReq,
            handleStatus: resolvedConsentAction,
            ver: store.consentRequestInfo?.ver!,
            FIPDetails: _shapeFIPDetails(accountslist),
            FIU: {
              id: store.consentRequestInfo?.FIU.id!,
            },
          };

          const retryLimitCount = retryLimit;
          let retryCount = 0;

          while (retryCount <= retryLimitCount) {
            try {
              const response = await aaSdk.value?.consent(request);
              const userConsentAction = operationWrapper.getModifiedResponse(
                response,
                aaHandle.value,
                "CONSENT_ACTION"
              );
              if (userConsentAction?.status === "SUCCESS") {
                if (
                  extraJourneyReq.value?.jType ===
                  JourneyType.AUTHORIZED_MULTI_CONSENT
                ) {
                  fireJourneyEvents(
                    ConductorEvent.CONSENT_GRANTED,
                    {},
                    {},
                    {
                      consentAction: resolvedConsentAction,
                      aaHandle: aaHandle.value,
                      childRequestId: utils.getKeyFromValue(
                        store.consentHandleMap,
                        consentHandleValue
                      ),
                    },
                    { internal: true }
                  );
                }
                return userConsentAction;
              } else if (
                userConsentAction?.status === "RETRY" ||
                userConsentAction?.status === "FAILURE"
              ) {
                retryCount++;
                if (retryCount > retryLimitCount) {
                  if (
                    extraJourneyReq.value?.jType ===
                    JourneyType.AUTHORIZED_MULTI_CONSENT
                  ) {
                    fireJourneyEvents(
                      ConductorEvent.CONSENT_FAILED,
                      {},
                      {},
                      {
                        consentAction: resolvedConsentAction,
                        aaHandle: aaHandle.value,
                        childRequestId: utils.getKeyFromValue(
                          store.consentHandleMap,
                          consentHandleValue
                        ),
                      },
                      { internal: true }
                    );
                  }
                  throw userConsentAction;
                }
              } else {
                retryCount++;
                if (
                  extraJourneyReq.value?.jType ===
                  JourneyType.AUTHORIZED_MULTI_CONSENT
                ) {
                  fireJourneyEvents(
                    ConductorEvent.CONSENT_FAILED,
                    {},
                    {},
                    {
                      consentAction: resolvedConsentAction,
                      aaHandle: aaHandle.value,
                      childRequestId: utils.getKeyFromValue(
                        store.consentHandleMap,
                        consentHandleValue
                      ),
                    },
                    { internal: true }
                  );
                }
                throw userConsentAction;
              }
              events.clearTimedEvents();
            } catch (error) {
              if (retryCount >= retryLimitCount) {
                throw error;
              }
            }
          }
        }
        /* } */
      )
    );

    /* handling consent responses, if atleast one is successful , its a success. 
      failure is only when all of them fail*/
    const allFailed = results.filter((re) => re.status === "rejected");
    const successFulConsent = results.filter(
      (re) => re.status === "fulfilled" && re.value !== undefined
    );
    if (allFailed.length === relevantConsents.size && allFailed.length > 0) {
      if (allFailed[0].status === "rejected") {
        if (allFailed[0].reason.status === "FAILURE") {
          handleFailures(
            ErrorCodes.FAILURE,
            ErrorTypes.FLOW_ERROR,
            allFailed[0].reason.message,
            true,
            aaErrorContext(allFailed[0].reason)
          );
        } else if (allFailed[0].reason.status === "SESSION_ERROR") {
          handleSessionError();
        } else if (allFailed[0].reason.status === "BAD_REQUEST") {
          handleBadRequest(
            ErrorCodes.BAD_REQUEST,
            ErrorTypes.INPUT_ERROR,
            allFailed[0].reason.message,
            true,
            aaErrorContext(allFailed[0].reason)
          );
        }
      }
    } else {
      if (successFulConsent.length > 0) {
        if (action === ConsentAction.ACCEPT || action === ConsentAction.DENY) {
          const institutions = utils
            .getUniqueElementsByKeys(selectedAccounts, ["fipId", "fipName"])
            .map((i: any) => {
              return {
                id: i.fipId,
                name: i.fipName,
              };
            });
          fireJourneyEvents(
            action === ConsentAction.ACCEPT
              ? ConductorEvent.CONSENT_GRANTED
              : ConductorEvent.CONSENT_DENIED,
            {},
            {},
            eventPayload(store.requestId, discriminator.value, {
              institutions,
            })
          );
        } else if (exitWithConsentReject) {
          fireJourneyEvents(ConductorEvent.CONSENT_DENIED);
        } else {
          // Fire an error instead
          const payload = eventPayload(store.requestId, discriminator.value, {
            consentAction: action,
            errorCode: "CONSENT_FAILED",
            errorType: "FLOW_ERROR",
            errorMessage: "Consent Failed due to error from AA",
            exitStatus: lastKnownStatus.value || LastStatus.UNKNOWN, // share the last known status in the journey
          });
          events.fire(ConductorEvent.ERROR, payload);
        }
        return Promise.all(successFulConsent).finally(() => {
          removeEventListeners();
        });
      }
    }
  }

  async function handleConsentAction11(
    action: ConsentAction,
    selectedAccounts: FinancialAccount[],
    exitWithConsentReject = true,
    retryCount = 0,
    retryLimit = 3
  ) {
    consentAction.value = action;
    const request = {
      consentHandleId: store.consentHandle!,
      handleStatus: action,
      ver: store.consentRequestInfo?.ver!,
      FIPDetails: _shapeFIPDetails(selectedAccounts),
      FIU: {
        id: store.consentRequestInfo?.FIU.id!,
      },
    };
    const retryLimitCount = retryLimit;
    return aaSdk.value
      ?.consent(request)
      .then(async (response: any) => {
        const userConsentAction = operationWrapper.getModifiedResponse(
          response,
          aaHandle.value,
          "CONSENT_ACTION"
        );
        if (userConsentAction?.status === "SUCCESS") {
          lastKnownStatus.value = LastStatus.CONSENT_ACTION_TAKEN;
          log.silly("Consent granted for request", store.requestId);
          if (action === ConsentAction.ACCEPT) {
            const institutions = utils
              .getUniqueElementsByKeys(selectedAccounts, ["fipId", "fipName"])
              .map((i: any) => {
                return {
                  id: i.fipId,
                  name: i.fipName,
                };
              });
            fireJourneyEvents(
              ConductorEvent.CONSENT_GRANTED,
              {},
              {},
              eventPayload(store.requestId, discriminator.value, {
                institutions,
              })
            );
          } else if (exitWithConsentReject) {
            fireJourneyEvents(ConductorEvent.CONSENT_DENIED);
            /*  _fireExit(
              "ALTERNATIVE_CHOSEN",
              "FLOW_ERROR",
              "Consent rejected by user",
              {
                consentStatus: action,
                status: lastKnownStatus.value,
              }
            ); */
          } else {
            // Fire an error instead
            const payload = eventPayload(store.requestId, discriminator.value, {
              errorCode: "CONSENT_REJECTED",
              errorType: "FLOW_ERROR",
              errorMessage: "Consent rejected by user",
              exitStatus: lastKnownStatus.value || LastStatus.UNKNOWN, // share the last known status in the journey
            });
            events.fire(ConductorEvent.ERROR, payload);
          }

          return true;
        } else if (userConsentAction?.status === "RETRY") {
          if (retryCount < retryLimitCount) {
            await handleConsentAction(
              action,
              selectedAccounts,
              true,
              ++retryCount,
              retryLimit
            );
          } else {
            handleFailures(
              ErrorCodes.FAILURE,
              ErrorTypes.FLOW_ERROR,
              userConsentAction?.message,
              true
            );
          }
        } else if (userConsentAction?.status === "BAD_REQUEST") {
          handleBadRequest(
            ErrorCodes.BAD_REQUEST,
            ErrorTypes.INPUT_ERROR,
            userConsentAction.message,
            true
          );
        } else if (userConsentAction?.status === "SESSION_ERROR") {
          handleSessionError();
        } else {
          handleFailures(
            ErrorCodes.FAILURE,
            ErrorTypes.FLOW_ERROR,
            userConsentAction?.message,
            true
          );
        }
      })
      .catch((e: any) => {
        if (e === ErrorMessages.SESSION_EXPIRED) {
          handleSessionError();
        } else {
          log.fatal(
            `Error while processing consent ${action} with AA`,
            aaHandle.value,
            e?.message | e
          );
          _fireExit(
            "AA_ERROR",
            "FLOW_ERROR",
            "Error while confirming consent with Account Aggregator",
            {
              consentStatus: action,
            }
          );
        }
      })
      .finally(() => {
        removeEventListeners();
      });
  }

  function _shapeFIPDetails(selectedAccounts: FinancialAccount[]) {
    const mapped = selectedAccounts.reduce((result, current) => {
      let ob = result.get(current.fipId);
      if (!ob) {
        ob = {
          FIP: {
            id: current.fipId,
          },
          Accounts: [],
        };
        result.set(current.fipId, ob);
      }
      const acc = { ...current } as any;
      delete acc.identifierSeq;

      ob.Accounts.push(acc); // now push

      return result;
    }, new Map<string, any>());
    return Array.from(mapped.values());
  }

  async function ensureNext() {
    if (awaitNext.value) {
      await awaitNext.value;
      awaitNext.value = undefined;
    }
  }

  function _fireExit(
    errorCode = "UNKNOWN",
    errorType = "UNKNOWN",
    errorMessage = "Unhandled error encountered",
    extraPayload = {}
  ) {
    const payload = eventPayload(store.requestId, discriminator.value, {
      errorCode,
      errorType,
      errorMessage,
      exitStatus: lastKnownStatus.value || LastStatus.UNKNOWN, // share the last known status in the journey
      ...extraPayload,
    });
    events.fire(ConductorEvent.EXIT, payload);
    //emit an event for redirection if required
    emitter.emit(InternalConductorEvent.JOURNEY_EXIT);
    // Trigger app_failure as well
    events.fire("app_failure", payload);
    events.clearTimedEvents();
  }

  async function denyAndExit(
    exitErrorMessage: string = "",
    errorCode: string = "CANCELLED",
    exitWithConsentReject: boolean = true,
    errorType = "FLOW_ERROR",
    aaOperation?: string,
    aaOpAttempt?: number
  ) {
    const canDeny = aaAuth.value;
    if (canDeny) {
      await handleConsentAction(ConsentAction.DENY, [], exitWithConsentReject);
      _fireExit(errorCode, errorType, "Unhandled error encountered", {
        aaOperation,
        aaOpAttempt,
      });
    } else if (
      !canDeny &&
      lastKnownStatus.value === LastStatus.REQUIRES_AA_OTP
    ) {
      _fireExit(errorCode, errorType, ErrorMessages.JOURNEY_CANCELLED, {
        aaOperation,
        aaOpAttempt,
      });
    }
    // If exit was not triggered by handleConsent, we need to do it now
    else if (!exitWithConsentReject || !canDeny) {
      _fireExit(errorCode, errorType, exitErrorMessage || "", {
        aaOperation,
        aaOpAttempt,
      });
    }
  }

  async function _initForAlternateMobile(mobileNumber: string, resend = false) {
    if (mobileNumber === store.customer.mobile) {
      return Promise.reject(ErrorMessages.MOBILE_SAME_AS_USERID);
    }
    const req = { mobileNum: mobileNumber };
    return new Promise<void>((resolve, reject) => {
      aaSdk.value
        ?.mobileAuthInit(req)
        .then((response: any) => {
          const altMobileWrapped = operationWrapper.getModifiedResponse(
            response,
            aaHandle.value,
            "ALT_MOBILE"
          );
          if (altMobileWrapped?.status === "SUCCESS") {
            //checkForOTP('ALT_MOBILE');
            store.additionalMobiles.push(mobileNumber); // not for navi
            fireJourneyEvents(
              resend
                ? ConductorEvent.ALT_MOBILE_AA_OTP_RESENT
                : ConductorEvent.ALT_MOBILE_AA_OTP_SENT,
              {},
              {},
              aaErrorContext(altMobileWrapped)
            );
            resolve();
          } else if (altMobileWrapped?.status === "RETRY") {
            // retriable?
            fireJourneyEvents(ConductorEvent.ALT_MOBILE_AA_OTP_RETRY);
            reject();
          } else if (altMobileWrapped?.status === "BAD_REQUEST") {
            handleBadRequest(
              ErrorCodes.BAD_REQUEST,
              ErrorTypes.INPUT_ERROR,
              altMobileWrapped.message,
              true,
              aaErrorContext(altMobileWrapped)
            );
          } else if (altMobileWrapped?.status === "SESSION_ERROR") {
            handleSessionError();
          } else {
            //error or any other response
            fireJourneyEvents(
              ConductorEvent.ALT_MOBILE_AA_OTP_FAILED,
              {},
              {},
              aaErrorContext(altMobileWrapped)
            );
            reject();
          }
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  async function _confirmAlternateMobile(
    mobileNumber: string,
    inpOtpFromUser: string
  ) {
    const request = {
      mobileNum: mobileNumber,
      otp: inpOtpFromUser,
    };
    return new Promise<void>((resolve, reject) => {
      aaSdk.value
        ?.mobileAuthVerify(request)
        .then((response: any) => {
          const altMobileWrapped = operationWrapper.getModifiedResponse(
            response,
            aaHandle.value,
            "CONFIRM_ALT_MOBILE"
          );

          if (altMobileWrapped?.status === "SUCCESS") {
            //store.additionalMobiles.push(mobileNumber);
            fireJourneyEvents(ConductorEvent.ALT_MOBILE_AA_OTP_VALID);
            resolve();
            //start discovery of the accounts using new mobile number
          } else if (altMobileWrapped?.status === "RETRY") {
            //otpMessage.value = response['message'];
            fireJourneyEvents(ConductorEvent.ALT_MOBILE_AA_OTP_INVALID);
            reject(ErrorMessages.OTP_VALIDATION_FAILED);
          } else if (altMobileWrapped?.status === "BAD_REQUEST") {
            fireJourneyEvents(ConductorEvent.ALT_MOBILE_AA_OTP_INVALID);
            reject(ErrorMessages.OTP_VALIDATION_FAILED);
            handleBadRequest(
              ErrorCodes.DATA_INVALID,
              ErrorTypes.INPUT_ERROR,
              altMobileWrapped.message,
              true,
              aaErrorContext(altMobileWrapped)
            );
          } else if (altMobileWrapped?.status === "FAILURE") {
            fireJourneyEvents(
              ConductorEvent.ALT_MOBILE_AA_OTP_INVALID,
              {},
              {},
              aaErrorContext(altMobileWrapped)
            );
            reject(ErrorMessages.OTP_VALIDATION_FAILED);
          } else if (altMobileWrapped?.status === "SESSION_ERROR") {
            handleSessionError();
          } else {
            fireJourneyEvents(ConductorEvent.ALT_MOBILE_AA_OTP_VERIFY_FAILED);
            reject();
          }
        })
        .catch((error: any) => {
          fireJourneyEvents(ConductorEvent.ALT_MOBILE_AA_OTP_VERIFY_FAILED);
          reject(error);
        });
    });
  }

  // Account linking related stuff
  async function initiateLinking(
    institution: Institution,
    selectedAccounts: FinancialAccount[],
    resend = false,
    retryCount = 0,
    retryLimit = 3
  ) {
    const fip = { fipId: institution.id, fipName: institution.name };
    const accounts = selectedAccounts.map((a) => a.shapedForLinking());
    const accountLinkingRequest = {
      FIPDetails: fip,
      Customer: {
        Accounts: accounts,
      },
    };
    const retryLimitCount = retryLimit;
    return await aaSdk.value
      ?.linkAccounts(accountLinkingRequest)
      .then(async (response: any) => {
        const linkingWrapped = operationWrapper.getModifiedResponse(
          response,
          aaHandle.value,
          "ACCOUNT_LINKING"
        );
        if (linkingWrapped?.status === "SUCCESS") {
          // OTP for linking sent successfully
          fireJourneyEvents(
            resend
              ? ConductorEvent.ACCOUNTS_LINKING_OTP_RESENT
              : ConductorEvent.ACCOUNTS_LINKING_OTP_SENT
          );
          return {
            retryNow: false,
            response: linkingWrapped.data as AccountLinkingResponseProto,
          };
        } else if (linkingWrapped?.status === "RETRY") {
          // update the caller to store the state and enable retry?
          return {
            retryNow: true,
            response: linkingWrapped.data as AccountLinkingResponseProto,
          };
        } else if (linkingWrapped?.status === "BAD_REQUEST") {
          if (retryCount < retryLimitCount) {
            await initiateLinking(
              institution,
              selectedAccounts,
              true,
              ++retryCount,
              retryLimit
            );
          } else {
            handleFailures(
              ErrorCodes.FAILURE,
              ErrorTypes.FLOW_ERROR,
              linkingWrapped.message,
              true,
              aaErrorContext(linkingWrapped)
            );
          }
        } else if (linkingWrapped?.status === "FAILURE") {
          handleFailures(
            ErrorCodes.FAILURE,
            ErrorTypes.FLOW_ERROR,
            linkingWrapped.message,
            true,
            aaErrorContext(linkingWrapped)
          );
        } else if (linkingWrapped?.status === "SESSION_ERROR") {
          handleSessionError();
        } else {
          handleBadRequest(
            ErrorCodes.UNKNOWN,
            ErrorTypes.INSTITUTION_ERROR,
            "",
            false,
            aaErrorContext(linkingWrapped)
          );
          log.error(
            `Error while initating account linking for ${fip.fipName} with AA`,
            aaHandle.value,
            response?.message
          );
        }
      })
      .catch((e: any) => {
        if (e === ErrorMessages.SESSION_EXPIRED) {
          handleSessionError();
          throw ErrorMessages.SESSION_EXPIRED;
        }
        log.fatal(
          `Error while initating account linking for ${fip.fipName} with AA`,
          aaHandle.value,
          e?.message | e
        );
      });
  }

  async function confirmLinking(
    otp: number,
    refNum: string,
    meta: Institution
  ) {
    const fipName = meta.name;
    const request = {
      AccountsLinkingRefNumber: refNum,
      token: otp,
    };
    //const request = new ConfirmLinkingRequest(refNum, otp);
    return await aaSdk.value
      ?.confirmLinking(request)
      .then((response: any) => {
        const confirmLinkingWrapped = operationWrapper.getModifiedResponse(
          response,
          aaHandle.value,
          "CONFIRM_ACCOUNT_LINKING"
        );

        if (confirmLinkingWrapped?.status === "SUCCESS") {
          // update the store
          fireJourneyEvents(ConductorEvent.ACCOUNTS_LINKING_OTP_VALID);
          store.linkDiscoveredAccounts(
            confirmLinkingWrapped.data as AccountLinkedResProto,
            meta
          );
          fireJourneyEvents(ConductorEvent.ACCOUNTS_LINKED);
        } else if (confirmLinkingWrapped?.status === "BAD_REQUEST") {
          fireJourneyEvents(ConductorEvent.ACCOUNTS_LINKING_OTP_INVALID);
          handleBadRequest(
            ErrorCodes.BAD_REQUEST,
            ErrorTypes.INPUT_ERROR,
            confirmLinkingWrapped.message,
            true,
            aaErrorContext(confirmLinkingWrapped)
          );
        } else if (confirmLinkingWrapped?.status === "FAILURE") {
          fireJourneyEvents(ConductorEvent.ACCOUNTS_LINKING_OTP_INVALID);
          handleBadRequest(
            ErrorCodes.FAILURE,
            ErrorTypes.FLOW_ERROR,
            confirmLinkingWrapped.message,
            true,
            aaErrorContext(confirmLinkingWrapped)
          );
        } else if (confirmLinkingWrapped?.status === "RETRY") {
          fireJourneyEvents(ConductorEvent.ACCOUNTS_LINKING_OTP_INVALID);
          log.error(ErrorMessages.OTP_VALIDATION_FAILED);
          throw new Error(ErrorMessages.OTP_VALIDATION_FAILED);
        } else if (confirmLinkingWrapped?.status === "SESSION_ERROR") {
          handleSessionError();
          throw new Error(ErrorMessages.SESSION_EXPIRED);
        } else {
          fireJourneyEvents(ConductorEvent.ACCOUNTS_LINKING_FAILED);
          handleBadRequest(
            ErrorCodes.UNKNOWN,
            ErrorTypes.INSTITUTION_ERROR,
            confirmLinkingWrapped?.message,
            false,
            aaErrorContext(confirmLinkingWrapped)
          );
          log.error(
            `Error while confirm account linking using ref: '${refNum}' for ${fipName} with AA`,
            aaHandle.value
          );
        }
      })
      .catch((e: any) => {
        if (e === ErrorMessages.SESSION_EXPIRED) {
          handleSessionError();
        }
        log.fatal(
          `Error while confirm account linking using ref with unknown error: '${refNum}' for ${fipName} with AA`,
          aaHandle.value,
          e?.message | e
        );
        throw e;
      });
  }

  function exitTheWorld(
    quitely: boolean = false,
    errorMessage?: string,
    errorCode?: string
  ) {
    if (quitely) {
      denyAndExit(
        errorMessage,
        errorCode,
        quitely /** quitely = don't trigger exit*/
      );
    } else {
      // show the modal
      exitWorld.value = true;
    }
  }

  function addMoreBankAccounts() {
    // viewHandler.value = components.selectBanks;
    transitionToView(views.selectBanks);
  }

 
/*   async function discoverAccountsFromInstitutions11(
    institutions: Institution | Array<Institution>,
    retryLimit?: number,
    auto = false,
    mobile?: string
  ) {
    const discoverList = Array.isArray(institutions) ? institutions : [institutions];
    
    isProcessing.value = true;
  
    let overallSuccess = false;
    const errors = [];
    const badRequests = [];


   
   
  
    try {
      for (const institution of discoverList) {


        store.updateDiscoveryStatus(
          {
            discovering: true,
            auto,
          },
          institution
        );
  
        let rawResult;

        if(layoutView === JOURNEY_LAYOUTS.V2){
           rawResult = await doAccountDiscovery(
            mobile || store.customer.mobile!,
            institution,
            institution.fiTypes,
            mobile ? 1 : 0,
            0,
            retryLimit,
            auto
          );
        }
        else{
           rawResult = await doAccountDiscovery(
            mobile || store.customer.mobile!,
            institution,
            store.financialInstruments,
            mobile ? 1 : 0,
            0,
            retryLimit,
            auto
          );
        }

        
  
        store.updateDiscoveryStatus(
          {
            discovering: false,
            auto,
          },
          institution
        );
  
        const result = await processDiscoveryResult(rawResult, auto, mobile ? 1 : 0) as any;
  
        if (result && result.sessionError) {
          return { success: false, sessionError: true };
        }
        if (result.additionalInfoRequired) {
          // Return if additional info is required
          return { success: false, additionalInfoRequired: true, institution: result.institution, auto:auto };
        }
  
        if (result.success) {
          overallSuccess = true;
        } else if (result && result?.badRequest) {
          badRequests.push(result?.badRequest);
        } else if (result.error) {
          errors.push(result.error);
        }
      }
  
      if (!overallSuccess && !bankFound.value && !somethingLoading.value) {
        handleFailures(
          ErrorCodes.UNKNOWN,
          ErrorTypes.FLOW_ERROR,
          "Account discovery failed for all institutions",
          true,
          { errors, badRequests }
        );
      } else if (errors.length > 0 || badRequests.length > 0) {
        console.warn("Some institutions failed or had bad requests during account discovery", { errors, badRequests });
      }
  
      return { success: overallSuccess, errors, badRequests };
    } catch (error) {
      console.error("An unexpected error occurred during account discovery", error);
      if(!bankFound.value && !somethingLoading.value){
        handleFailures(
          ErrorCodes.UNKNOWN,
          ErrorTypes.FLOW_ERROR,
          "An unexpected error occurred during account discovery",
          true,
          { error }
        );
      }
     
      return { success: false, errors: [error], badRequests: [] };
    } finally {
      isProcessing.value = false;
    }
  } */

  async function discoverAccountsFromInstitutions(
    institutions: Institution | Array<Institution>,
    retryLimit?: number,
    auto = false,
    mobile?: string
  ) {
    const discoverList = Array.isArray(institutions) ? institutions : [institutions];

    const extraIdentifiersNeeded:any = [];
    const identifierTypes: any = new Set();
    discoverList.forEach((element) => {
      if (layoutView === JOURNEY_LAYOUTS.V2 && isInfoRequired(element)) {
        const identifiers = typeOfInfoRequired(element);
    
        identifiers.forEach((identifier) => {
          const type = identifier.type;
          if (type !== undefined && !identifierTypes.has(type)) {
            identifierTypes.add(type);
            extraIdentifiersNeeded.push(identifier);
          } 
        });
      } 
    });
    
    extraIdentifiersList.value = extraIdentifiersNeeded;
    askAdditionalInfo.value = true;
    
    isProcessing.value = true;
  
    let overallSuccess = false;
    const errors: any[] = [];
    const badRequests: any[] = [];
  
    for (const institution of discoverList) {
      console.log(`Starting discovery for: ${institution.id}`);
      
      let institutionProcessed = false;
      let retryCount = 0;
  
      while (!institutionProcessed && retryCount < (retryLimit || 3)) {
        try {
          store.updateDiscoveryStatus(
            {
              discovering: true,
              auto,
            },
            institution
          );
  
          const rawResult = await doAccountDiscovery(
            mobile || store.customer.mobile!,
            institution,
            store.financialInstruments,
            mobile ? 1 : 0,
            retryCount,
            retryLimit,
            auto
          ) as any;
  
  
          if (rawResult.status === 'ADDITIONAL_INFO_REQUIRED') {
            console.log(`Additional info required for institution: ${institution.id}`);
            
            // Wait for additional info
            await new Promise<void>((resolve) => {
              doAccDiscoveryCallback = async () => {
                console.log(`Callback triggered for institution: ${institution.id}`);
                resolve();
              };
              askAdditionalInfo.value = true;
            });
            
            console.log(`Additional info received for institution: ${institution.id}`);
            askAdditionalInfo.value = false;
            
            // Continue to next iteration of while loop
            retryCount++;
            continue;
          }
  
          const result = await processDiscoveryResult(rawResult, auto, mobile ? 1 : 0) as any;
  
          if (result.sessionError) {
            console.error(`Session error for institution: ${institution.id}`);
            errors.push({ institution: institution.id, error: 'Session error' });
            break; // Exit the while loop, move to next institution
          }
  
          if (result.success) {
            overallSuccess = true;
            institutionProcessed = true;
          } else if (result?.badRequest) {
            badRequests.push(result.badRequest);
            institutionProcessed = true;
          } else if (result.error) {
            errors.push(result.error);
            institutionProcessed = true;
          }
  
        } catch (error:any) {
          console.error(`Error processing institution ${institution.id}:`, error);
          errors.push({ institution: institution.id, error: error.message || 'Unknown error' });
          institutionProcessed = true; // Consider it processed even if there's an error
        } finally {
          console.log('discovery completed maybe', institution.id);
          store.updateDiscoveryStatus(
            {
              status:DiscoveryStatus.FAILED,
              discovering: false,
              auto,
            },
            institution
          );
        }
  
        retryCount++;
      }
  
      console.log(`Completed discovery for: ${institution.id}`);
    }
  
    if (!overallSuccess && !bankFound.value && !somethingLoading.value) {
      handleFailures(
        ErrorCodes.UNKNOWN,
        ErrorTypes.FLOW_ERROR,
        "Account discovery failed for all institutions",
        true,
        { errors, badRequests }
      );
    } else if (errors.length > 0 || badRequests.length > 0) {
      console.warn("Some institutions failed or had bad requests during account discovery", { errors, badRequests });
    }
  
    isProcessing.value = false;
    return { success: overallSuccess, errors, badRequests };
  }

  async function processDiscoveryResult(rawResult:any, auto = false,idSeq?:number) {
    if (rawResult.status === 'SESSION_ERROR') {
      handleSessionError();
      return { success: false, sessionError: true };
    }

    if (rawResult.status === 'ADDITIONAL_INFO_REQUIRED') {
      return { success: false, additionalInfoRequired: true, institution: rawResult.institution, auto:auto };
    }
  
    if (rawResult.status === 'ERROR') {
      return { 
        success: false, 
        error: { institution: rawResult.institution.id, error: rawResult.error }
      };
    }
  
    const { rawResponse, institution, latency, request } = rawResult;
    const wrappedResult = operationWrapper.getModifiedResponse(
      rawResponse,
      aaHandle.value,
      "ACCOUNT_DISCOVERY"
    );

  
    const result = { success: false, error: null, badRequest: null };
    
    switch (wrappedResult?.status) {
      case 'SUCCESS':{
        result.success = true;
        const filteredDiscoveredAccounts = store.financialInstruments.length > 0
          ? getFilteredAccounts(wrappedResult?.data.DiscoveredAccounts)
          : wrappedResult?.data.DiscoveredAccounts;
        store.updateDiscoveredAccounts(filteredDiscoveredAccounts, institution, idSeq);
        store.updateDiscoveryStatus({ status: DiscoveryStatus.SUCCESS, auto,discovering:false  }, institution);
        fireJourneyEvents(ConductorEvent.ACCOUNTS_DISCOVERED, request, {}, { auto });
        break;
      }

      case 'RETRY':{
        store.updateDiscoveryStatus({ status: DiscoveryStatus.NO_ACCOUNTS, auto, discovering: false }, institution);
        fireJourneyEvents(
          ConductorEvent.ACCOUNTS_DISCOVERY_FAILED,
          {},
          {},
          {
            aaHandle: aaHandle.value,
            cause: CAUSE.NO_DATA,
            // type: CAUSE_TYPE.FIP_ERROR,
            fipId: institution.id,
            auto: auto,
            ...aaErrorContext(wrappedResult)
          },
          
        );
        log.debug(
          "Successful discovery response, but no accounts found from",
          institution.id
        );
        break;
      }
   

        
  
      case 'BAD_REQUEST':{
        result.badRequest = { institution: institution.id, error: wrappedResult?.message } as any;
        store.updateDiscoveryStatus({ status: DiscoveryStatus.FAILED, auto,discovering:false }, institution);
        events.fire(
          ConductorEvent.ACCOUNTS_DISCOVERY_FAILED,
          {
            aaHandle: aaHandle.value,
            fipId: institution.id,
            auto: auto,
            ...aaErrorContext(wrappedResult)
          },
        );
        if (!auto  && !somethingLoading.value) {
          handleBadRequest(
            ErrorCodes.BAD_REQUEST,
            ErrorTypes.FLOW_ERROR,
            wrappedResult?.message,
            true,
            ...aaErrorContext(wrappedResult)
          );
        }
        break;
  
      }
        
      case 'FIP_UNAVAILABLE':
      case 'UNKNOWN_ERROR':
      default:{
        result.error = { institution: institution.id, error: wrappedResult?.message || wrappedResult?.status } as any;
        store.updateDiscoveryStatus({ status: DiscoveryStatus.FAILED, auto, discovering: false }, institution);
        fireJourneyEvents(
          ConductorEvent.ACCOUNTS_DISCOVERY_FAILED,
          {},
          {},
          {
            aaHandle: aaHandle.value,
            cause: wrappedResult?.status === 'FIP_UNAVAILABLE' ? CAUSE.FIP_UNAVAILABLE : CAUSE.UNKNOWN,
            fipId: institution.id,
            auto,
            ...aaErrorContext(wrappedResult)
          },
         
        );
        break;
      }
        
    }
  
    fireJourneyEvents(
      ConductorEvent.JOURNEY_METRIC,
      request,
      {},
      {
        name: "FIP:AA:UserDiscoveryResponse",
        aaHandle: aaHandle.value,
        fipId: institution.id,
        latency,
        success: wrappedResult?.status === 'SUCCESS',
      },
      { internal: true }
    );
  
    return result;
  }

  function navigateBack(from?: string) {
    if (from === "selectBanks") {
      // render the discovery view
      // viewHandler.value = components.discovery;
      transitionToView(views.discovery);
    }
  }
  //if the session is expired, ask user to login and restart again
  function handleSessionError() {
    if (layoutView === JOURNEY_LAYOUTS.V2) {
      sessionError.value = true;
      return;
    }
    const ctx = {
      error: true,
      title: "Session timeout",
      useDefaultDesc: false,
      primaryText: "Retry Again",
      secondaryText: "Try Another Method",
      onSecondary: () =>
        denyAndExit(
          "Session Error", // message for SDK users
          ErrorCodes.ALTERNATIVE_CHOSEN, // errorCode
          false, // false => don't exit once consent is rejected
          ErrorTypes.INSTITUTION_ERROR // errorType
        ),
      onPrimary: () => retryLogin(),
      btnStyle: "space-x-2",
    };
    _raiseErrorEventForAA(
      "Session timeout",
      "AA_ERROR",
      ErrorTypes.INSTITUTION_ERROR,
      {
        aaHandle: aaHandle.value,
        retriable: true,
        status: lastKnownStatus.value,
      }
    );
    // invoke troubleshoot helper and ask user to restart the journey
    invokeTroubleshoot(ctx, true);
    eventEmitter.emit(InternalConductorEvent.SESSION_ERROR);
  }

  async function retryLogin() {
    // reset complete store??
    //store.otpReference = undefined;
    //store.aaAuth = false;
    sessionError.value = false;
    isProcessing.value = true;
    eventEmitter.all.clear();
    store.$reset();
    await init(store.requestId);
    isProcessing.value = false;
  }

  function handleBadRequest(
    errorCode = ErrorCodes.BAD_REQUEST,
    errorType = ErrorTypes.API_ERROR,
    errorMessage = "Bad request",
    aaError = true,
    aaErrorCtx = {}
  ) {
    //handle common bad request and generate terminal error
    const ctx = {
      error: true,
      title: "Sorry, could not fetch details",
      useDefaultDesc: true,
      primaryText: "Try other method",
      onPrimary: () =>
        denyAndExit(
          errorMessage, // message for SDK users
          errorCode, // errorCode
          false, // false => don't exit once consent is rejected
          errorType // errorType
        ),
    };
    if (aaError) {
      //some issue with the data sent to AA, hence exit the journey
      _raiseErrorEventForAA(
        "Some issue in the request that is sent to AA",
        ConductorEvent.AA_ERROR,
        ErrorTypes.INSTITUTION_ERROR,
        {
          aaHandle: aaHandle.value,
          retriable: false,
          status: lastKnownStatus.value,
          ...aaErrorCtx,
        }
      );
    }
    // invoke troubleshoot helper
    invokeTroubleshoot(ctx);
    eventEmitter.emit(InternalConductorEvent.BAD_REQUEST, {
      errorCode,
      errorType,
      errorMessage,
      aaError,
    });
  }

  async function resendMobileAuthOTP(mobileNum: string) {
    //reset store to get updated valuee
    await _initForAlternateMobile(mobileNum);
  }

  function handleAPIError(
    title: string,
    desc: string,
    errorMessage: string,
    errorCode = ErrorCodes.INVALID_CONFIG,
    errorType = ErrorTypes.API_ERROR
  ) {
    if (
      store.viewsType?.error === COMPONENT_VIEW_TYPE.DEFAULT ||
      layoutView === JOURNEY_LAYOUTS.V2
    ) {
      const error = {
        errorCode: ERROR_RESPONSE_CODES.DATA_NOT_FOUND,
        errorMessage: "Some error occurred! Please try again in sometime.",
      };
      xStore.errorXValue = error;
      transitionToView(v2Views.xErrorPage);
    } else {
      const ctx = {
        error: true,
        title: title,
        description: desc,
        useDefaultDesc: false,

        primaryText: "Got it, try other method",
        onPrimary: () => denyAndExit(errorMessage, errorCode, false, errorType),
      };
      // invoke troubleshoot helper
      invokeTroubleshoot(ctx);
    }
  }

  function fireJourneyEvents(
    eventType: any,
    request: any = {},
    errorObject = {} as any,
    extras = {},
    labels = {}
  ) {
    let error = {};
    //add specific request details in extraparams
    const extraParams = {
      aaHandle: aaHandle.value,
      requestId: store.requestId,
    };
    if (Object.entries(errorObject).length > 0) {
      error = {
        errorCode: errorObject.errorCode,
        errorType: errorObject.errorType,
        errorMessage: errorObject.errorMessage,
      };
    }
    const finalPayload = { ...extraParams, ...error, ...extras };
    events.fire(
      eventType,
      eventPayload(store.requestId, discriminator.value, finalPayload),
      labels
    );
  }

  /*   function getAABasedOnFip(selectedFips: any) {
    let preferredAa = [] as any;
    if (knownTenantFipAaPrefs.has("navi")) {
      const tenantPrefs = knownTenantFipAaPrefs.get("navi")!;
      preferredAa = getPrefAAList(selectedFips, tenantPrefs);
    }
    return preferredAa.length > 0 ? preferredAa[0] : ["nadl"];
  } */

  function getPrefAAList(filtered: any[], fipMappings: Map<string, string>) {
    const prefList = [];
    for (const value of filtered) {
      if (fipMappings.has(value)) {
        const aaList = fipMappings.get(value) || [];
        if (aaList.length > 0) {
          prefList.push(aaList);
        }
      }
    }
    return prefList.length > 0 ? prefList : ["finvu"];
  }
  // some failures which can't be handled at our end

  function handleFailures(
    errorCode = ErrorCodes.FAILURE,
    errorType = ErrorTypes.API_ERROR,
    errorMessage = "Failure",
    aaError = true,
    aaErrorCtx = {}
  ) {
    //handle common bad request and generate terminal error
    const ctx = {
      error: true,
      title: "Sorry, could not fetch details",
      useDefaultDesc: true,
      primaryText: "Try other method",
      onPrimary: () =>
        denyAndExit(
          errorMessage, // message for SDK users
          errorCode, // errorCode
          false, // false => don't exit once consent is rejected
          errorType // errorType,
        ),
    };
    if (aaError) {
      //some issue with the data sent to AA, hence exit the journey
      _raiseErrorEventForAA(
        "Some issue in the request that is sent to AA",
        ConductorEvent.AA_ERROR,
        ErrorTypes.INSTITUTION_ERROR,
        {
          aaHandle: aaHandle.value,
          retriable: false,
          status: lastKnownStatus.value,
          ...aaErrorCtx,
        }
      );
    }
    // invoke troubleshoot helper
    invokeTroubleshoot(ctx);
    eventEmitter.emit(InternalConductorEvent.FAILURES, {
      errorCode,
      errorType,
      errorMessage,
      aaError,
      aaErrorCtx,
    });
  }

  async function switchAA() {
    if (aaHandle.value === "nadl") {
      aaHandle.value = "finvu";
    }
    isProcessing.value = true;
    resetStore.store;
    await _initForAaLogin();
    isProcessing.value = false;
  }

  function collectTopPrefFips() {
    const countForAutoDisc = store.getFeature(
      ConductorFeatures.AUTO_DISCOVERY_FIP_COUNT
    ) as number;
    const filteredFips = utils.loadFipsBasedOnAA(
      store.consentDetail.value.fiTypes,
      Array.from(store.getInstitutions().value.values()),
      aaHandle.value
    );

    return filteredFips.slice(0, countForAutoDisc);
  }
  async function autoDiscovery(mobile?: string) {
    //use the collected top fips & trigger discovery
    const collectFips = collectTopPrefFips() as any;
    const autoRetryLimit = store.getFeature(
      ConductorFeatures.ACCOUNTS_AUTO_DISCOVERY_LIMIT
    ) as number;
    const knownFilteredFips = filterKnownFips(
      Array.from(store.getInstitutions().value.values()),
      filterParser.value?.getInstitutions() as Set<string>
    );
    autoRetryCount.value += 1;
    const accFilterList = Array.from(knownFilteredFips).map((data) => {
      return store.getInstitutions().value.get(data.toString());
    });
    let autoDiscList = [] as any;
    if (mobile) {
      // take the fip from account filter also to trigger alt mobile autodiscovery
      if (filterParser.value) {
        autoDiscList = Array.from(new Set(accFilterList.concat(collectFips)));
      } else {
        autoDiscList = collectFips;
      }
      await discoverAccountsFromInstitutions(
        autoDiscList,
        autoRetryLimit,
        true,
        mobile
      );
      if (!bankFound.value) {
        emitter.emit(InternalConductorEvent.ALT_AUTO_DISCOVERY_FAILED);
        //in case of alternate mobile goes to missing account
        //transitionToView(views.missingAccount);
      }
    } else {
      // remove fip present in the account filter so that we dont trigger re-discovery

      autoDiscList = collectFips.filter(
        (item: any) => !Array.from(knownFilteredFips).includes(item.id)
      );
      await discoverAccountsFromInstitutions(
        collectFips,
        autoRetryLimit,
        true,
        store.customer.mobile!
      );
      if (!bankFound.value) {
        emitter.emit(InternalConductorEvent.AUTO_DISCOVERY_FAILED);
      }
    }
  }

  function cleanUpFips(institution: any) {
    if (Array.isArray(institution)) {
      institution.forEach((element) => {
        const fa = institutionWiseAccounts.value.get(element.id);
        if (fa && fa.getDiscoveredAccStatus() !== DiscoveryStatus.SUCCESS) {
          institutionWiseAccounts.value.delete(element.id);
        }
      });
    } else {
      const fa = institutionWiseAccounts.value.get(institution.id);
      if (fa && fa.getDiscoveredAccStatus() !== DiscoveryStatus.SUCCESS) {
        institutionWiseAccounts.value.delete(institution.id);
      }
    }
  }  


  //manipulate browser / mobile back buttons
  const onLoad = disableBackOnLoad.bind(null);
  const onPopState = disableOnPopState.bind(null);
  function disableBackOnLoad() {
    window.history.pushState({}, "");
  }
  function disableOnPopState() {
    window.history.pushState({}, "");
    exitWorld.value = true;
  }
  function removeEventListeners() {
    window.removeEventListener("load", onLoad);
    window.removeEventListener("popstate", onPopState);
  }

  watch(removeListener, (newValue, oldValue) => {
    if (newValue !== oldValue) {
      removeEventListeners();
    }
  });

  //set journey features
  function setJourneyFeatures(data: any) {
    const defaultFeatures = DefaultFeatures();
    const listOfDefault = defaultFeatures.getListOfDefaults(layoutView);
    // Configure layout Features, set defaults if the values are missing from the feature list
    //  data?.features&& Object.keys(data.features).length > 0 --- add this when mrging in dev to the below condiction
    const resolvedFeatureList = data?.features ? data.features : {};
    for (const [key, value] of listOfDefault) {
      const featureValue =
        resolvedFeatureList[key] !== undefined
          ? resolvedFeatureList[key]
          : value;
      store.setFeature(key, featureValue);
    }

    // filtering out the design/theme features which affect the layout
    // const layoutThemes = filterTemplates('design', store.features);
    // store.themeTemplates = utils.splitTemplateString(layoutThemes, 3, undefined);
    store.themeTemplates = {
      colorFont: features.value.get(ConductorFeatures.COLOR_FONT),
      colorPrimary: features.value.get(ConductorFeatures.COLOR_PRIMARY),
      colorSecondary: features.value.get(ConductorFeatures.COLOR_SECONDARY),
      colorLoader: features.value.get(ConductorFeatures.COLOR_LOADER),
      colorSelection: features.value.get(ConductorFeatures.COLOR_SELECTION),
      colorInput: features.value.get(ConductorFeatures.COLOR_INPUT),
      colorInputActive: features.value.get(
        ConductorFeatures.COLOR_INPUT_ACTIVE
      ),
      colorInputFocus: features.value.get(ConductorFeatures.COLOR_INPUT_FOCUS),
      colorInputDisabled: features.value.get(
        ConductorFeatures.COLOR_INPUT_DISABLED
      ),
      fontFamily: features.value.get(ConductorFeatures.FONT_FAMILY),
      otpInputType: features.value.get(ConductorFeatures.OTP_INPUT_TYPE),
      otpInputSplitCount: features.value.get(
        ConductorFeatures.OTP_INPUT_SPLIT_COUNT
      ),
      roundedInput: features.value.get(ConductorFeatures.ROUNDED_INPUT),
      roundedButton: features.value.get(ConductorFeatures.ROUNDED_BUTTON),
      backgroundBody: features.value.get(ConductorFeatures.BACKGROUND_BODY),
      backgroundPrimary: features.value.get(
        ConductorFeatures.BACKGROUND_PRIMARY
      ),
      backgroundSecondary: features.value.get(
        ConductorFeatures.BACKGROUND_SECONDARY
      ),
      customBorder: features.value.get(ConductorFeatures.CUSTOM_BORDER),
      strokeColor: features.value.get(ConductorFeatures.STROKE_COLOR),
    };

    initializeDebugEvents();
  }

  function setJourneyTemplates(data: any) {
    const defaultTempates = DefaultTemplates();
    // change here tenant value to get the tempaltes

    const listOfDefaultTemplates =
      defaultTempates.getListOfDefaultTemplates(layoutView);
    // Configure tempalte Features, set defaults if the values are missing from the template list
    const resolvedTemplateList = data?.templates ? data.templates : {};
    if (listOfDefaultTemplates) {
      for (const [key, value] of listOfDefaultTemplates) {
        const templateValue =
          resolvedTemplateList[key] !== undefined
            ? resolvedTemplateList[key]
            : value;
        store.setTemplate(key, templateValue);
      }
    }
    // separate the view related features heree
    const viewTemplates = filterTemplates("view", store.features);
    store.viewsType = utils.splitTemplateString(viewTemplates, 3, 4);
  }

  function triggerExitWorld(
    quite = false,
    errMsg?: string,
    errCode?: string,
    auto = false
  ) {
    //conductor.exitTheWorld(quite, errMsg, errCode);
    //isLoading.value = true;

    if (auto) {
      //autoDiscoveryCount.value += 1;
      fireJourneyEvents(
        ConductorEvent.AUTO_DISCOVERY_MODE,
        {},
        {},
        { userInitiated: true }
      );
      autoDiscovery();
      store.autoDiscoveryTrigger = undefined;
    } else {
      exitTheWorld(quite, errMsg, errCode);
    }
    //isLoading.value = false;
    //console.log(autoDiscoveryCount.value)
  }

  function filterTemplates(type: string, listOfTemplates: any) {
    const templateMap = new Map<string, any>();
    for (const [key, value] of listOfTemplates) {
      // depends on type of view
      if (key.includes(type)) {
        templateMap.set(key, value);
      }
    }
    return templateMap;
  }

  function selectAAFromFips() {
    // try and use the filters and/or stored selected FIP(s)
    let selectedFips: String[] = [];
    if (filterParser.value) {
      selectedFips =
        filterParser.value?.getInstitutions().size > 0
          ? Array.from(filterParser.value?.getInstitutions())
          : [];
    } else {
      selectedFips = store.selectedFipList;
    }
    const availableFips = Array.from(store.getInstitutions().value.values());
    try {
      if (selectedFips.length !== 0) {
        aaHandle.value = utils.getAAFromFip(
          selectedFips,
          availableFips,
          store.availableAAs,
          store.tenantId
        );
        //aaHandle.value = 'finvu'
      } else {
        aaHandle.value =
          store.availableAAs.length > 0
            ? store.availableAAs[0].handle
            : "finvu";

            //aaHandle.value = 'finvu'
      }
    } catch (error: any) {
      if (error?.message === ErrorMessages.UNSUPPORTED_FIP) {
        aaHandle.value = store.availableAAs[0].handle;
        fireJourneyEvents(
          ConductorEvent.UNAVAILABLE_FIP_SELECTED,
          JSON.stringify(selectedFips)
        );
      }
    }
    events.fire(
      ConductorEvent.AA_SELECTED,
      eventPayload(store.requestId, discriminator.value, {
        aaHandle: aaHandle.value,
        autoSelection: true,
      })
    );
  }

  // for one & only V2X
  async function getXDetails() {
    if (
      extraJourneyReq.value !== undefined &&
      extraJourneyReq.value.id &&
      extraJourneyReq.value.ts &&
      extraJourneyReq.value.iv
    ) {
      const id = extraJourneyReq.value.id;
      const data = extraJourneyReq.value.data;
      const ts = extraJourneyReq.value.ts;
      const iv = extraJourneyReq.value.iv;
      xStore.isV2X = true;

      //optional
      const request = {
        id: id,
        data: data,
        ts: ts,
        iv: iv,
      };

      await api
        .getConsentData(request)
        .then((response: any) => {
          if (response) {
            xStore.updateXDetails(response.data);
            /* router.push({
              name: "v2u.landing",
              params: { identifier: xid.value },
            }); */
            transitionToView(views.selectBanks);
          } else {
            (xStore.brandInfo.color = "#1C357E"),
              (xStore.brandInfo.logo =
                "https://finarkein.com/img/logo.93c8e6ce.svg"),
              (xStore.brandInfo.name = "Finarkein Analytics");
          }
        })
        .catch((e: any) => {
          console.log("Failed to process the request, error = " + e.message);
          console.log(e);
          (brandInfo.value.color = "#1C357E"),
            (brandInfo.value.logo =
              "https://finarkein.com/img/logo.93c8e6ce.svg"),
            (brandInfo.value.name = "Finarkein Analytics");
          if (e?.response?.status === 400) {
            if (e.response.data && e.response.data.orgInfo) {
              xStore.updateXDetails(e.response.data);
            }

            const error = {
              errorCode: e.response.data.errorCode,
              errorMessage: e.response.data.errorMessage,
            };
            xStore.errorXValue = error;
            transitionToView(views.xErrorPage);
          } else if (
            e.response.data.errorCode === ERROR_RESPONSE_CODES.DATA_NOT_FOUND
          ) {
            const error = {
              errorCode: ERROR_RESPONSE_CODES.DATA_NOT_FOUND,
              errorMessage:
                "Some error occurred! Please try again in sometime.",
            };
            xStore.errorXValue = error;
            transitionToView(views.xErrorPage);
          }
        });
    }
  }

  function createConsentRequest(request: any, type: string) {
    api
      .createConsentRequest(xid.value, request)
      .then(async (response: any) => {
        //we will get the request id from response , send to AA login

        if (response.status === 200) {
          reqId.value = response.data.requestId;
          if (reqId.value) {
            await updateAndGetWebviewParams(aaHandle.value);
            isProcessing.value = false;
            if (type === "proceed") {
              if (reqId.value) {
                router.push({
                  name: "v2u.landing",
                  params: { identifier: reqId.value },
                });
              }
              //selectAAFromFips();
              store.requestId = reqId.value;
              await getListOfFips(reqId.value);
              prepareAccountAggregator();
              transitionToView(views.login);
            } else if (type === "sendLink") {
              const statusRequest = {
                requestId: reqId.value,
                status: journeyStatus.linkSent,
                message: "Link sent through SMS/Email",
                actionTimestamp: new Date().toISOString(),
              };
              await api.sendStatusUpdates(reqId.value, statusRequest);
              transitionToView(views.xProgress);
            }
          }
        }
      })
      .catch((e: any) => {
        console.log(e);

        if (e?.response?.status === 400) {
          const error = {
            errorCode: e.response.data.errorCode,
            errorMessage:
              "Some error occured. Please contact your administrator!!",
          };
          errorXValue.value = error;
          transitionToView(views.xErrorPage);
        }
        console.log("Failed to process the request, error" + e.message);
        //window.location.href = alternateRedirectUrl.value;
      });
  }

  function resolveConfigCatFeatures() {
    const finalTenant = computed(() => {
      return tId.value ? tId.value : tenantId.value;
    });
    const configCat = useFeatures(finalTenant.value);
    const dummyEncrypt = configCat.getFeature(
      "cfmPortalRedirectResponseDummyEncrypt",
      false
    );
    const redirectButton = configCat.getFeature(
      "cfmPortalRedirectOnButtonClick",
      false
    );
    const modeOfRedirection = configCat.getFeature(
      "cfmPortalRedirectXMode",
      "both"
    );
    const tenantSpecificJourney = configCat.getFeature(
      "cfmPortalCustomAaUx",
      "finvu"
    );
    Promise.all([
      dummyEncrypt,
      redirectButton,
      modeOfRedirection,
      tenantSpecificJourney,
    ]).then((flags: any[]) => {
      forTenantRedirectParams.value = flags[0];
      showRedirectButton.value = flags[1];
      redirectMode.value = flags[2];
      customAAJourneyList.value = flags[3];
    });
  }

  function unsupportedBank() {
    const statusRequest = {
      requestId: reqId.value ? reqId.value : xid.value,
      status: journeyStatus.cancelled,
      message: "Bank Unsupported",
      actionTimestamp: new Date().toISOString(),
    };
    //exit.value = true;
    api
      .sendStatusUpdates(reqId.value ? reqId.value : xid.value, statusRequest)
      .then((response: any) => {
        if (response) {
          redirectToTenant(xStore.alternateRedirectUrl);
        }
      });
    //console.log(redirectToTenant(alternateRedirectUrl.value));
  }

  function redirectToTenant(url: string) {
    const finalUrl = url;
    if (
      redirectMode.value === REDIRECT_MODE_ENUM.anubhavx ||
      redirectMode.value === REDIRECT_MODE_ENUM.both
    ) {
      if (finalUrl && finalUrl.length > 0) {
        const redirectUrl = finalUrl;
        const url: URL = new URL(redirectUrl);
        const addedParams = url.searchParams;
        if (forTenantRedirectParams.value) {
          addedParams.set(
            "ecres",
            "9HjME5CiZOyg0BSdqKPWTXKLLFIoJX-k4Q8oBcO_Ci4nn5JTxQ2vG5RYOnGc5hiiosHXlIsMxfroFcTrcwjNP0JyiqqrI8wo88R70z_XDNd-xbsmsAYIeLi_AyBpgVKbwvX-pBemDhUzRtL6OA5HznMxWcDLqmjuXClZGxcxdZllLJPFbukeKCzvdlEMMNU1o7oqinAq41H25OaqgqFua_aO2tXh59desCcHgkFK8hhpQO2EYH9Qa2ULEHhfDI4L"
          );
          addedParams.set("resdate", "180120231959012");
          addedParams.set("fi", "BEhRWEFRH1VYTA==");
          window.location.href = url.toString();
        } else if (xid.value && !forTenantRedirectParams.value) {
          const id = xid.value;
          api.getResponseParams(id).then((response) => {
            if (response) {
              addedParams.set("ts", response.data.ts);
              addedParams.set("data", response.data.data);
              addedParams.set("iv", response.data.iv);
              window.location.href = url.toString();
            }
          });
        }
      }
    }
  }

  function handleAvailableAccountFilters(
    data: any,
    requestId: string,
    view?: any
  ) {
    filterParser.value = new AccountFilterParser(data.accountFilters);
    if (filterParser.value.getInstitutions().size > 0) {
      // EVENT: FIPs are selected
      // TODO: Change to INSTITUTION_SELECTED ?
      events.fire(
        ConductorEvent.FIP_SELECTED,
        eventPayload(requestId, discriminator.value, {
          institutions: Array.from(filterParser.value.getInstitutions()).map(
            (v) => {
              return {
                id: v, // @DEPRECATED
                fipId: v,
              };
            }
          ),
        })
      );

      // We need to move to aa-login view
      lastKnownStatus.value = LastStatus.REQUIRES_AA_SELECTION;

      //get bank list before we initialize with AA
      selectAAFromFips();
      if (layoutView == JOURNEY_LAYOUTS.V7) {
        transitionToView(view);
      } else {
        prepareAccountAggregator();
        transitionToView(views.login);
      }
      return; // go back from here!
    }
  }

  function handleUnavailableAccountFilters(
    data: any,
    requestId: string,
    view?: any
  ) {
    lastKnownStatus.value = LastStatus.REQUIRES_AA_SELECTION;

    switch (layoutView) {
      case JOURNEY_LAYOUTS.V5:
        selectAAFromFips();
        prepareAccountAggregator();
        transitionToView(views.login);
        break;
      case JOURNEY_LAYOUTS.V2:
        eventEmitter.emit(InternalConductorEvent.NO_ACCOUNTS_FOUND);
        transitionToView(views.selectBanks);
        break;
      case JOURNEY_LAYOUTS.V6:
      case JOURNEY_LAYOUTS.V8:
        transitionToView(views.selectBanks);
        break;
      case JOURNEY_LAYOUTS.V7:
        selectAAFromFips();
        transitionToView(view);
        break;
      default:
        log.fatal(
          "Unable to complete the journey at the moment, no institution provided"
        );
        handleBadRequest(
          ErrorCodes.INVALID_INSTITUTION,
          ErrorTypes.INPUT_ERROR,
          ErrorMessages.NO_INSTITUTION,
          false
        );
    }

    return; // go back from here
  }

  function getRequiredIdentifiers(toBeDiscoveredFip: Institution) {
    // check here if required details are present or not
    let filteredUserDetails = [];
    if (store.extraUserDetails && store.extraUserDetails.length > 0) {
      filteredUserDetails = store.extraUserDetails?.filter(
        (eud: { type: string; value: string | undefined }) =>
          toBeDiscoveredFip.identifiers!.some(
            (df) =>
              df.type === eud.type &&
              df.type !== "MOBILE" &&
              (eud.value !== undefined || eud.value !== "")
          )
      );

      filteredUserDetails = filteredUserDetails.map(
        (eud: { type: any; value: any }) => {
          const matchingIdentifier = toBeDiscoveredFip.identifiers?.find(
            (df) => df.type === eud.type
          );
          return {
            type: eud.type,
            category: matchingIdentifier?.category,
            value: eud.value,
          };
        }
      );
    }
    return filteredUserDetails?.length ? filteredUserDetails : [];
  }

  

  function isInfoRequired(providedInst: Institution) {
    // check here if extra info is required or not
    const availableData = [];
    const nonAvailableData = [];

    for (const inst of providedInst?.identifiers!) {
      const matchingExtra = extraUserDetails.value.find(
        (data: { type: any; value: any }) =>
          data.type === inst.type &&
          (data.value !== undefined || data.value !== "")
      );
      if (inst.type !== "MOBILE") {
        if (matchingExtra) {
          availableData.push(matchingExtra);
        } else {
          nonAvailableData.push(inst);
       
        }
      }
    }
    
    return nonAvailableData.length > 0 ? true : false;
  }

  function typeOfInfoRequired(providedInst: Institution){
    const availableData = [];
    const nonAvailableData = [];
    for (const inst of providedInst?.identifiers!) {
      const matchingExtra = extraUserDetails.value.find(
        (data: { type: any; value: any }) =>
          data.type === inst.type &&
          (data.value !== undefined || data.value !== "")
      );
      if (inst.type !== "MOBILE") {
        if (matchingExtra) {
          availableData.push(matchingExtra);
        } else {
          nonAvailableData.push(inst);
        }
      }
    }
    return nonAvailableData;
  }


  const isExtraIdentifierProcessing = ref(false);

  async function updateExtraUserDetails($event: any) {
    isExtraIdentifierProcessing.value = true;
    setTimeout(async () => {
      askAdditionalInfo.value = false;
      $event.forEach((ele: { type: any; category: any; value: any }) => {
        const element = extraUserDetails.value.filter(
          (ei: { type: any }) => ei.type === ele.type
        );
        if (element.length === 0) {
          extraUserDetails.value.push({
            type: ele.type,
            category: ele.category,
            value: ele.value,
          });
        } else {
          element[0].type = ele.type;
          element[0].category = ele.category;
          element[0].value = ele.value;
          const somethingNew = extraUserDetails.value.map(
            (obj: { type: any }) => (obj.type === ele.type ? element[0] : obj)
          );
          extraUserDetails.value = somethingNew;
        }
      });
      if (doAccDiscoveryCallback) {
        await doAccDiscoveryCallback();
      }
      isExtraIdentifierProcessing.value =false;
    }, 500);

  }

  async function getListOfFips(id?: string) {
    await api
      .getBankList(
        id ? id : store.requestId,
        0,
        1000,
        store.extraJourneyReq?.receivedAuth
      )
      .then((response) => {
        const data = response.data;
        store.updateInstitutions(data.content); // paginated response this is!
        log.silly(
          "Fetched all financial institutions for requestId:",
          store.requestId
        );
      })
      .catch((e: any) => {
        log.fatal(
          "Fetched all financial institutions for requestId:",
          store.requestId,
          e
        );
      });
  }

  //mode and grouping as per features

  function transformText(text: string) {
    if (text === "DEPOSIT") {
      return "Bank Account(s)";
    }
    const words = text.split("_");
    const transformedText = words
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");

    return transformedText;
  }

  const isSelected = (id: string, selectedBank: any) => {
    return selectedBank.value.includes(id);
  };

  function toggleSelectFip(id: string, selectedBank: any, mode: any) {
    if (mode == SELECTION_MODE.SINGLE) {
      if (!isSelected(id, selectedBank)) {
        selectedBank.value = [];
        selectedBank.value.push(id);
      } else {
        selectedBank.value.splice(selectedBank.value.indexOf(id), 1);
      }
    } else {
      if (isSelected(id, selectedBank)) {
        selectedBank.value.splice(selectedBank.value.indexOf(id), 1);
      } else {
        selectedBank.value.push(id);
      }
    }
  }

  function isBankSelected(groupId: any, bankId: any, selectedBanks: any) {
    return selectedBanks.value.some(
      (group: { groupId: any; bankIds: string | any[] }) => {
        if (group.groupId === groupId) {
          return group.bankIds.includes(bankId);
        }
        return false;
      }
    );
  }

  function toggleSelectAllFip(
    groupId: string,
    bankId: string,
    selectedBanks: any,
    mode: any
  ) {
    const groupIndex = selectedBanks.value.findIndex(
      (group: any) => group.groupId === groupId
    );

    if (mode == SELECTION_MODE.SINGLE) {
      if (
        groupIndex !== -1 &&
        selectedBanks.value[groupIndex].bankIds.includes(bankId)
      ) {
        // If the bank is already selected, deselect it
        selectedBanks.value = [];
      } else {
        // If mode is single, clear the existing selections and add the new one
        selectedBanks.value = [
          {
            groupId,
            bankIds: [bankId],
          },
        ];
      }
    } else {
      if (groupIndex === -1 && bankId) {
        // If the group doesn't exist and bankId is provided, create it and add the bankId
        selectedBanks.value.push({
          groupId,
          bankIds: [bankId],
        });
      } else if (groupIndex !== -1) {
        // If the group exists, update the selected bank IDs
        const group = selectedBanks.value[groupIndex];
        const bankIndex = group.bankIds.indexOf(bankId);

        if (bankIndex === -1 && bankId) {
          // Add the bank to the group if bankId is provided
          group.bankIds.push(bankId);
        } else if (bankIndex !== -1) {
          // Remove the bank from the group
          group.bankIds.splice(bankIndex, 1);
        }

        // If the group becomes empty, remove the group
        if (group.bankIds.length === 0) {
          selectedBanks.value.splice(groupIndex, 1);
        }
      }
    }
  }
  //rn the request key is static
  async function getBulkFileuPload(input: []) {
    try {
      const response = await api.getBulkFileuPload(
        store.requestId,
        input,
        store.extraJourneyReq?.receivedAuth
      );
      return response;
    } catch (e) {
      console.log(e);
      throw e;
    }
  }

  function getFilteredAccounts(availableAccounts: any) {
    return availableAccounts.filter((acc: { FIType: string }) => {
      return store.listOfFinancialInstruments.includes(acc.FIType);
    });
  }

  function prepareAccountsForConsentAction(listOfAccounts: FinancialAccount[]) {
    const consentAccountsMap = new Map<string, FinancialAccount[]>();
    for (const [key, value] of store.consentMappedWithHandle) {
      const totalAccounts = listOfAccounts.filter((loa) =>
        value.def.fiTypes.includes(loa.FIType)
      );
      // consent template id & list of relevant accounts
      consentAccountsMap.set(key, totalAccounts);
    }
    return consentAccountsMap;
  }

  function flaggedEvents(eventName:string, payload:Record<string,any>, labels?:Record<string, any>){
    if(store.getFeature(ConductorFeatures.DEBUG_EVENTS, false)){
      events.fire(eventName, payload,labels);
    }
  }

  function initializeDebugEvents(){
      // initialize debug events
    const debugEvents = store.getFeature(ConductorFeatures.DEBUG_EVENTS,false);
    const debugHeartBeat = store.getFeature(ConductorFeatures.DEBUG_EVENTS_FIRE_HEARTBEAT, debugEvents);
    const canFireHeartbeatEvents = debugHeartBeat!==undefined ? debugHeartBeat : debugEvents;
    canFireHeartbeatEvents ? events.canFireHeartbeatEvents() : '';
  }

  function fetchUserConsentedAccounts(){
     api.getUserConsentedAccounts(store.requestId,store.extraJourneyReq?.receivedAuth).then((response) => {
      if(response){
        userConsentedAccounts.value = response.data.content
      }
    
    })
  }

  function relevantLinkedAccounts(availableLinkedAccounts: FinancialAccount[]){
    const filteredFips = filterParser.value?.getInstitutions()  ? Array.from(filterParser.value?.getInstitutions()) || [] : [];

    // consider fips in account filters or selected fips are user selection
    const fipSelectedByUser = Array.from(new Set(store.selectedFipList.concat(filteredFips))) as string[];
    // prepare availableFips Set from the given relevant linked accounts;
    const availableFips = new Set(availableLinkedAccounts.map(account => account.fipId));
  
    // Check if all selectedFips are in the availableFips Set
    const allFipsPresent = fipSelectedByUser.every((fip: string) => availableFips.has(fip));
    return allFipsPresent;
  }

  return {
    missingAcc,
    totalAccountsCount,
    bankFound,
    anythingLoading,
    viewHandler,
    currentView,
    previousHandler,
    init,
    ensureNext,
    cancelAndExit: denyAndExit,
    exitTheWorld,
    handleSessionError,
    handleBadRequest,
    handleAPIError,

    // AA specific stuff
    resendAaOTP,
    verifyAaOTPAndNext,
    handleConsentAction,
    doAccountDiscovery,
    _initForAlternateMobile,
    _confirmAlternateMobile,
    initiateLinking,
    confirmLinking,
    addMoreBankAccounts,

    //exporting the components
    //components: views,
    //getInstitutionListForDisplay
    discoverAccountsFromInstitutions,
    navigateBack,
    resendMobileAuthOTP,

    //events
    fireJourneyEvents,
    //getAABasedOnFip,
    handleFailures,
    switchAA,
    autoDiscovery,
    collectTopPrefFips,
    transitionToView,
    views,

    // for disabling back functionality
    disableBackOnLoad,
    disableOnPopState,
    onLoad,
    onPopState,
    removeEventListeners,

    // feature resolvers
    setJourneyFeatures,
    triggerExitWorld,
    filterTemplates,
    fipFilters,
    filterKnownFips,
    prepareAccountAggregator,
    prepareAccountsAndDiscoveryLinking,
    selectAAFromFips,
    //v2X variables
    getXDetails,
    createConsentRequest,
    resolveConfigCatFeatures,
    unsupportedBank,
    redirectToTenant,

    retryLogin,
    getRequiredIdentifiers,
    isInfoRequired,
    updateExtraUserDetails,

    toggleSelectFip,
    isSelected,
    transformText,
    isBankSelected,
    toggleSelectAllFip,
    getBulkFileuPload,
    eventPayload,
    isExtraIdentifierProcessing,
    flaggedEvents,
    cleanUpFips
  };
}
