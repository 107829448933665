import {
  ConductorEvent,
  ErrorMessages,
  LastStatus,
} from "@/conductor/journey-constants";
import { computed, ref } from "vue";
import useUtilService from "@/services/util.service";
import { JourneyConductorType, JourneyStoreType } from "@/conductor/models";
import emitter from "../event.emitter";
import { storeToRefs } from "pinia";
import { JOURNEY_LAYOUTS } from "@/constants/constants";

const { validatePayloadForAutoReadOTP } = useUtilService();
export default function useAltMobile(
  store: JourneyStoreType,
  conductor: JourneyConductorType
) {
  const institutions = store.getInstitutions();
  const institution = ref(); // working object for trying with new mobile
  const inpMobileNo = ref("");
  const inpMobileOtp = ref("" as any);
  const mobileOtpView = ref(false);
  const isLoading = ref(false);
  const timerStart = ref(false);
  const inpWrong = ref(false);
  const inpErrorText = ref("");
  const inpSuccess = ref(false);
  const eventEmitter = emitter;
  const { triggerAltMobile, autoDiscoveryTrigger, templates, aaHandle } =
    storeToRefs(store);
  const institutionList = ref(); // alt mobile for multiple institutions
  const { splitTemplateString } = useUtilService();
  function addMobileModal(institutionId: any) {
    //which institution /bank we want to trigger discovery
    institution.value = institutions.value.get(institutionId);
    triggerAltMobile.value = true;
    inpWrong.value = false;
    inpErrorText.value = "";
    mobileOtpView.value = false;
    timerStart.value = false;
    inpMobileNo.value = "";
  }
  async function mobileOtpModal() {
    isLoading.value = true;
    inpSuccess.value = false;
    inpWrong.value = false;
    inpErrorText.value = "";
    mobileOtpView.value = false;
    inpMobileOtp.value = "";
    await tryAlternateMobile();
  }
  async function tryAlternateMobile() {
    isLoading.value = true;
    await conductor
      ._initForAlternateMobile(inpMobileNo.value, false)
      .then(() => {
        isLoading.value = false;
        inpSuccess.value = false;
        inpErrorText.value = "";
        //triggerAltMobile.value = false;
        mobileOtpView.value = true;
        timerStart.value = true;
        autoReadOTP("ALT_MOBILE");
        store.lastKnownStatus = LastStatus.REQUIRES_ALT_MOBILE_OTP;
      })
      .catch((error: any) => {
        //if session expired
        isLoading.value = false;
        timerStart.value = false;
        if (error === ErrorMessages.SESSION_EXPIRED) {
          conductor.handleSessionError();
        } else if (error === ErrorMessages.MOBILE_SAME_AS_USERID) {
          console.log(error);
          inpWrong.value = true;
          inpErrorText.value = error;
        } else {
          console.log(error);
          inpWrong.value = true;
          inpErrorText.value = error;
        }
      });
  }

  function autoReadOTP(type: string) {
    switch (type) {
      case "ALT_MOBILE":
        eventEmitter.on("OTP_DETECTED", async (e: any) => {
          if (validatePayloadForAutoReadOTP(e)) {
            inpMobileOtp.value = e.value;
            await newMobileOtpModal();
          }
        });
        break;
    }
  }

  async function newMobileOtpModal() {
    inpSuccess.value = false;
    inpWrong.value = false;
    isLoading.value = true;
    await confirmMobileAuthentication(conductor);
  }

  async function confirmMobileAuthentication(
    conductor: JourneyConductorType,
    auto = false,
    next?: any,
    layout?: JOURNEY_LAYOUTS
  ) {
    await conductor
      ._confirmAlternateMobile(inpMobileNo.value, inpMobileOtp.value)
      .then(async () => {
        inpSuccess.value = true;
        mobileOtpView.value = false;
        triggerAltMobile.value = false;
        isLoading.value = false;
        inpErrorText.value = "";
        inpMobileOtp.value = "";
        //inpMobileNo.value = "";
        //inpSuccess.value = false;

  


        //after verification of alternate mobile , trigger discovery
        if (institution.value && !auto) {
          await tryDiscovery();
        } else if (!institution.value && !auto && !institutionList.value) {
          const knownFilteredFips = conductor.filterKnownFips(
            Array.from(store.getInstitutions().value.values()),
            store.filterParser?.getInstitutions() as Set<string>
          );
          let accFilterList = [] as any;
          accFilterList =
            Array.from(knownFilteredFips).map((data) => {
              return store.getInstitutions().value.get(data.toString());
            }) || [];
          if (accFilterList.length > 0) {
            conductor.discoverAccountsFromInstitutions(
              accFilterList,
              undefined,
              auto,
              inpMobileNo.value
            );
          } else {
            conductor.fireJourneyEvents(
              ConductorEvent.AUTO_DISCOVERY_MODE,
              {},
              {},
              { userInitiated: true }
            );
            await conductor.autoDiscovery(inpMobileNo.value);
          }
        } else if (institutionList.value && institutionList.value!.length > 0) {
          conductor.discoverAccountsFromInstitutions(
            institutionList.value,
            undefined,
            auto,
            inpMobileNo.value
          );
        } else {
          conductor.fireJourneyEvents(
            ConductorEvent.AUTO_DISCOVERY_MODE,
            {},
            {},
            { userInitiated: true }
          );
          await conductor.autoDiscovery(inpMobileNo.value);
          if (next && layout) {
            conductor.transitionToView(next, layout);
          }
        }
      })
      .catch((error: ErrorMessages) => {
        console.log(error);
        inpSuccess.value = false;
        isLoading.value = false;
        // triggerAltMobile.value = false;
        if (error === ErrorMessages.SESSION_EXPIRED) {
          conductor.handleSessionError();
        } else if (error === ErrorMessages.OTP_VALIDATION_FAILED) {
          if(aaHandle.value === "finvu"){
            inpWrong.value = true;
            inpErrorText.value = templateValue.value.altMobile_otpValidationErrorText || "Invalid OTP. Click on resend OTP and retry again!";
          }
          else{
            inpErrorText.value = templateValue.value.altMobile_otpValidationErrorText || "Invalid OTP. Please try again!";
          }
          
        }
        else if (error === ErrorMessages.OTP_EXPIRED) {
          inpWrong.value = true;
          inpErrorText.value = "Otp already used or expired. Please retry";
        }
      })
      .finally(() => {
        //inpSuccess.value = false;
        //inpErrorText.value = "";
        //inpMobileNo.value = "";
      });
  }

  async function tryDiscovery() {
    store.updateDiscoveryStatus(
      {
        discovering: true,
      },
      institution.value
    );
    await conductor.doAccountDiscovery(
      inpMobileNo.value,
      institution.value,
      store.financialInstruments,
      1
    );
  }

  async function tryAgain() {
    inpErrorText.value = "";
    inpWrong.value = false;
    //means trying with alternate mobile number, assumption is we already have instituion id in place
    if (mobileOtpView.value) {
      inpMobileOtp.value = "";
      //triggerAltMobile.value = true;
      return await conductor._initForAlternateMobile(inpMobileNo.value, true);
    }
  }

  function triggerAltMobileDiscovery() {
    triggerAltMobile.value = true;
    autoDiscoveryTrigger.value = undefined;
  }

  const templateValue = computed(() => {
    const altMobileTemplates = conductor.filterTemplates(
      "altMobile",
      templates.value
    );
    return splitTemplateString(altMobileTemplates, 1, undefined);
  });

  return {
    inpMobileNo,
    inpMobileOtp,
    mobileOtpView,
    isLoading,
    timerStart,
    inpWrong,
    inpErrorText,
    inpSuccess,
    triggerAltMobile,
    tryAlternateMobile,
    confirmMobileAuthentication,
    mobileOtpModal,
    tryAgain,
    tryDiscovery,
    addMobileModal,
    newMobileOtpModal,
    institution,
    triggerAltMobileDiscovery,
    institutionList,
    templateValue,
  };
}
