import {
  COMPONENT_VIEW_TYPE,
  DOC_UPLOAD,
  GROUPING_MODE,
  JOURNEY_LAYOUTS,
  MANUAL_UPLOAD_REDIRECTION,
  MANUAL_UPLOAD_REDIRECTION_BUTTON,
  OTP_INPUT_TYPE,
  SELECTION_MODE,
  TERMS_AND_CONDITIONS_TYPE,
  EXIT_FEEDBACK,
  LAYOUT_TYPE,
} from "../constants/constants";
import {
  ConductorFeatures,
  FILE_UPLOAD_STATUS_CODES,
} from "./journey-constants";

//v3 layout feature list & defaults
export const v3LayoutFeatureListWithDefaults = new Map<String, any>();
v3LayoutFeatureListWithDefaults.set("ui.aa.consent.multi.allowSelection", false);
v3LayoutFeatureListWithDefaults.set(
  ConductorFeatures.ACCOUNTS_AUTO_SELECT,
  true
);
v3LayoutFeatureListWithDefaults.set(ConductorFeatures.FIP_ALT_MOBILE, false);
v3LayoutFeatureListWithDefaults.set(
  ConductorFeatures.ACCOUNTS_AUTO_DISCOVERY_LIMIT,
  1
);
v3LayoutFeatureListWithDefaults.set(
  ConductorFeatures.AUTO_DISCOVERY_FIP_COUNT,
  10
);
v3LayoutFeatureListWithDefaults.set(
  ConductorFeatures.ACCOUNTS_AUTO_DISCOVERY,
  true
);
v3LayoutFeatureListWithDefaults.set(ConductorFeatures.CONSENT_AUTO_GRANT, true);
v3LayoutFeatureListWithDefaults.set(
  ConductorFeatures.MANUAL_AUTO_DISCOVERY_RETRY,
  false
);

v3LayoutFeatureListWithDefaults.set(
  ConductorFeatures.SELECT_FIPS_VIEW_TYPE,
  COMPONENT_VIEW_TYPE.DEFAULT
);
v3LayoutFeatureListWithDefaults.set(
  ConductorFeatures.ACCOUNTS_VIEW_TYPE,
  COMPONENT_VIEW_TYPE.DEFAULT
);
v3LayoutFeatureListWithDefaults.set(
  ConductorFeatures.LOGIN_VIEW_TYPE,
  COMPONENT_VIEW_TYPE.DEFAULT
);
v3LayoutFeatureListWithDefaults.set(
  ConductorFeatures.TERMS_AND_CONDITIONS,
  TERMS_AND_CONDITIONS_TYPE.OTHERS
);
v3LayoutFeatureListWithDefaults.set(ConductorFeatures.AA_ALT_MOBILE, true);
v3LayoutFeatureListWithDefaults.set(ConductorFeatures.AA_ACCOUNT_FILTER, "");

// design defaults
// v3LayoutFeatureListWithDefaults.set(ConductorFeatures.FONT_FAMILY,'Sans Serif');
// v3LayoutFeatureListWithDefaults.set(ConductorFeatures.FONT_FAMILY_URL,'');
// v3LayoutFeatureListWithDefaults.set(ConductorFeatures.CTA_DESIGN,'two-button');
// v3LayoutFeatureListWithDefaults.set(ConductorFeatures.PRIMARY_COLOR,'#FF5732');
// v3LayoutFeatureListWithDefaults.set(ConductorFeatures.BODY_BACKGROUND_COLOR,'#FFFFFF');
// v3LayoutFeatureListWithDefaults.set(ConductorFeatures.SECONDARY_COLOR,'#191919');
// v3LayoutFeatureListWithDefaults.set(ConductorFeatures.LOADER_COLOR,'#FF5732');
// v3LayoutFeatureListWithDefaults.set(ConductorFeatures.SELECTION_COLOR,'#FF5732');
// v3LayoutFeatureListWithDefaults.set(ConductorFeatures.INPUT_DESIGN_ROUNDED,true);
// v3LayoutFeatureListWithDefaults.set(ConductorFeatures.INPUT_DESIGN_DISABLE,true);
// v3LayoutFeatureListWithDefaults.set(ConductorFeatures.OTP_INPUT,OTP_INPUT_TYPE.STANDARD);
// v3LayoutFeatureListWithDefaults.set(ConductorFeatures.OTP_INPUT_SPLIT_COUNT,6);// aa based , currently default to 6
// //v3LayoutFeatureListWithDefaults.set(ConductorFeatures.LAYOUT_TYPE,'clv1');// can be clv1, clv2, hlv2,hlv1,,default etc.

v3LayoutFeatureListWithDefaults.set(ConductorFeatures.COLOR_FONT, "#1A1A1A");
v3LayoutFeatureListWithDefaults.set(ConductorFeatures.COLOR_PRIMARY, "#FF5732");
v3LayoutFeatureListWithDefaults.set(
  ConductorFeatures.COLOR_SECONDARY,
  "#F5F5F5"
);
v3LayoutFeatureListWithDefaults.set(ConductorFeatures.COLOR_LOADER, "#FF5732");
v3LayoutFeatureListWithDefaults.set(
  ConductorFeatures.COLOR_SELECTION,
  "#FF5732"
);
v3LayoutFeatureListWithDefaults.set(ConductorFeatures.COLOR_INPUT, "#E3E5E5");
v3LayoutFeatureListWithDefaults.set(
  ConductorFeatures.COLOR_INPUT_ACTIVE,
  "#E3E5E5"
);
v3LayoutFeatureListWithDefaults.set(
  ConductorFeatures.COLOR_INPUT_FOCUS,
  "#E3E5E5"
);
v3LayoutFeatureListWithDefaults.set(
  ConductorFeatures.COLOR_INPUT_DISABLED,
  "#E3E5E5"
);
v3LayoutFeatureListWithDefaults.set(ConductorFeatures.FONT_FAMILY, "");
v3LayoutFeatureListWithDefaults.set(
  ConductorFeatures.OTP_INPUT_TYPE,
  OTP_INPUT_TYPE.STANDARD
);
v3LayoutFeatureListWithDefaults.set(ConductorFeatures.OTP_INPUT_SPLIT_COUNT, 6);
v3LayoutFeatureListWithDefaults.set(ConductorFeatures.ROUNDED_INPUT, true);
v3LayoutFeatureListWithDefaults.set(ConductorFeatures.ROUNDED_BUTTON, true);
v3LayoutFeatureListWithDefaults.set(
  ConductorFeatures.BACKGROUND_BODY,
  "#FFFFFF"
);
v3LayoutFeatureListWithDefaults.set(
  ConductorFeatures.BACKGROUND_PRIMARY,
  "#FF5732"
);
v3LayoutFeatureListWithDefaults.set(
  ConductorFeatures.BACKGROUND_SECONDARY,
  "#F5F5F5"
);

v3LayoutFeatureListWithDefaults.set(ConductorFeatures.CUSTOM_BORDER, "#FF5732");
v3LayoutFeatureListWithDefaults.set(ConductorFeatures.STROKE_COLOR, "#FF5732");
v3LayoutFeatureListWithDefaults.set(ConductorFeatures.ASK_USER_DETAILS, false);
v3LayoutFeatureListWithDefaults.set(ConductorFeatures.DEBUG_EVENTS, true);
v3LayoutFeatureListWithDefaults.set(ConductorFeatures.DEBUG_EVENTS_FIRE_HEARTBEAT, true);
v3LayoutFeatureListWithDefaults.set(ConductorFeatures.SHOW_MASKED_MOBILE, false);

// v5 layout list & defaults
export const v5FeatureListWithDefaults = new Map<String, any>();
v5FeatureListWithDefaults.set(ConductorFeatures.ACCOUNTS_AUTO_SELECT, false);
v5FeatureListWithDefaults.set(
  ConductorFeatures.ACCOUNTS_AUTO_DISCOVERY_LIMIT,
  1
);
v5FeatureListWithDefaults.set(ConductorFeatures.AUTO_DISCOVERY_FIP_COUNT, 10);
v5FeatureListWithDefaults.set(ConductorFeatures.ACCOUNTS_AUTO_DISCOVERY, true);

v5FeatureListWithDefaults.set(ConductorFeatures.CONSENT_AUTO_GRANT, false);
v5FeatureListWithDefaults.set(
  ConductorFeatures.TERMS_AND_CONDITIONS,
  TERMS_AND_CONDITIONS_TYPE.OTHERS
);
v5FeatureListWithDefaults.set(ConductorFeatures.AA_ALT_MOBILE, true);
v5FeatureListWithDefaults.set(ConductorFeatures.ALLOW_REDIRECTION, true);
v5FeatureListWithDefaults.set(ConductorFeatures.ASK_USER_DETAILS, false);
v5FeatureListWithDefaults.set(
  ConductorFeatures.SELECT_FIPS_VIEW_TYPE,
  COMPONENT_VIEW_TYPE.DEFAULT
);
v5FeatureListWithDefaults.set(
  ConductorFeatures.ACCOUNTS_VIEW_TYPE,
  COMPONENT_VIEW_TYPE.DEFAULT
);
v5FeatureListWithDefaults.set(
  ConductorFeatures.LOGIN_VIEW_TYPE,
  COMPONENT_VIEW_TYPE.DEFAULT
);
v5FeatureListWithDefaults.set(
  ConductorFeatures.THANK_YOU_VIEW_TYPE,
  COMPONENT_VIEW_TYPE.DEFAULT
);
v5FeatureListWithDefaults.set(
  ConductorFeatures.CONSENT_VIEW_TYPE,
  COMPONENT_VIEW_TYPE.DEFAULT
);
v5FeatureListWithDefaults.set(
  ConductorFeatures.MISSING_ACCOUNT_VIEW_TYPE,
  COMPONENT_VIEW_TYPE.MODAL
);
v5FeatureListWithDefaults.set(
  ConductorFeatures.ALT_MOBILE_VIEW_TYPE,
  COMPONENT_VIEW_TYPE.DEFAULT
);
v5FeatureListWithDefaults.set(
  ConductorFeatures.LAYOUT_TYPE,
  LAYOUT_TYPE.DEFAULT
);
v5FeatureListWithDefaults.set(ConductorFeatures.DEBUG_EVENTS, true);
v5FeatureListWithDefaults.set(ConductorFeatures.DEBUG_EVENTS_FIRE_HEARTBEAT, true);
v5FeatureListWithDefaults.set(ConductorFeatures.SHOW_LOADING_VIEWS, false);
v5FeatureListWithDefaults.set(ConductorFeatures.SHOW_AA_LOGIN_VERIFY, true);
v5FeatureListWithDefaults.set(ConductorFeatures.ACCOUNTS_COMPONENT, 'NEW');



// v6 layout list & defaults

export const v6FeatureListWithDefaults = new Map<String, any>();
v6FeatureListWithDefaults.set(ConductorFeatures.ACCOUNTS_AUTO_SELECT, false);
v6FeatureListWithDefaults.set(
  ConductorFeatures.ACCOUNTS_AUTO_DISCOVERY_LIMIT,
  1
);
v6FeatureListWithDefaults.set(ConductorFeatures.AUTO_DISCOVERY_FIP_COUNT, 10);
v6FeatureListWithDefaults.set(ConductorFeatures.ACCOUNTS_AUTO_DISCOVERY, true);

v6FeatureListWithDefaults.set(ConductorFeatures.CONSENT_AUTO_GRANT, false);
v6FeatureListWithDefaults.set(
  ConductorFeatures.TERMS_AND_CONDITIONS,
  TERMS_AND_CONDITIONS_TYPE.OTHERS
);
v6FeatureListWithDefaults.set(ConductorFeatures.AA_ALT_MOBILE, false);
v6FeatureListWithDefaults.set(
  ConductorFeatures.ASK_USER_DETAILS,
  FILE_UPLOAD_STATUS_CODES
);
v6FeatureListWithDefaults.set(ConductorFeatures.DEBUG_EVENTS, false);
v6FeatureListWithDefaults.set(ConductorFeatures.DEBUG_EVENTS_FIRE_HEARTBEAT, false);

// v2 layout list & defaults

export const v2FeatureListWithDefaults = new Map<String, any>();
v2FeatureListWithDefaults.set(ConductorFeatures.ACCOUNTS_MAX_SELECTIONS, 100);
v2FeatureListWithDefaults.set(ConductorFeatures.ALT_MOBILE, false);
v2FeatureListWithDefaults.set(
  ConductorFeatures.AA_JOURNEY_ERROR,
  COMPONENT_VIEW_TYPE.DEFAULT
);
v2FeatureListWithDefaults.set(
  ConductorFeatures.SELECTION_MODE,
  SELECTION_MODE.MULTIPLE
);
v2FeatureListWithDefaults.set(
  ConductorFeatures.GROUPING_MODE,
  GROUPING_MODE.UNGROUPED
);

//need to test these
v2FeatureListWithDefaults.set(
  ConductorFeatures.MANUAL_UPLOAD_REDIRECTION,
  MANUAL_UPLOAD_REDIRECTION.NONE
);
v2FeatureListWithDefaults.set(
  ConductorFeatures.MANUAL_UPLOAD_REDIRECTION_BUTTON,
  MANUAL_UPLOAD_REDIRECTION_BUTTON.HIDE
);
//till here

v2FeatureListWithDefaults.set(
  ConductorFeatures.DOC_UPLOAD,
  DOC_UPLOAD.UNAVAILABLE
);

v2FeatureListWithDefaults.set(
  ConductorFeatures.EXIT_FEEDBACK,
  EXIT_FEEDBACK.DISABLE
);

v2FeatureListWithDefaults.set(
  ConductorFeatures.DOC_UPLOAD_SUCCESS_REDIRECTION_MILLISECONDS,
  5000
);

//consent auto grant
v2FeatureListWithDefaults.set(ConductorFeatures.CONSENT_AUTO_GRANT, false);

v2FeatureListWithDefaults.set(ConductorFeatures.ASK_USER_DETAILS, true);
v2FeatureListWithDefaults.set(ConductorFeatures.AUTO_DISCOVERY_FIP_COUNT, 10);
v2FeatureListWithDefaults.set("ui.aa.journey.views.thank-you.type", "default");
v2FeatureListWithDefaults.set(ConductorFeatures.DEBUG_EVENTS, false);
v2FeatureListWithDefaults.set(ConductorFeatures.DEBUG_EVENTS_FIRE_HEARTBEAT, false);
//
// v7 features
export const v7FeatureListWithDefaults = new Map<String, any>();
v7FeatureListWithDefaults.set(ConductorFeatures.AUTO_MOVE_LINKING, false);
v7FeatureListWithDefaults.set(ConductorFeatures.ACCOUNTS_AUTO_SELECT, false);
v7FeatureListWithDefaults.set(ConductorFeatures.AUTO_DISCOVERY_FIP_COUNT, 10);
v7FeatureListWithDefaults.set(ConductorFeatures.ACCOUNTS_AUTO_DISCOVERY, true);
v7FeatureListWithDefaults.set(ConductorFeatures.ACCOUNTS_FITYPE_GROUPING, [
  "EQUITIES",
]);
v7FeatureListWithDefaults.set(
  ConductorFeatures.ACCOUNTS_CONSENT_VIEW_SELECTION_TYPE,
  "all"
);
v7FeatureListWithDefaults.set(
  ConductorFeatures.ACCOUNTS_CONSENT_VIEW_DEFAULT_SELECTED,
  true
);
v7FeatureListWithDefaults.set(ConductorFeatures.ASK_USER_DETAILS, false);
v7FeatureListWithDefaults.set(ConductorFeatures.AA_ALT_MOBILE, true);
v7FeatureListWithDefaults.set(ConductorFeatures.DEBUG_EVENTS, false);
v7FeatureListWithDefaults.set(ConductorFeatures.DEBUG_EVENTS_FIRE_HEARTBEAT, false);

// v8 features
export const v8FeatureListWithDefaults = new Map<String, any>();
v8FeatureListWithDefaults.set(ConductorFeatures.AUTO_MOVE_LINKING, false);
v8FeatureListWithDefaults.set(ConductorFeatures.ACCOUNTS_AUTO_SELECT, false);
v8FeatureListWithDefaults.set(ConductorFeatures.AUTO_DISCOVERY_FIP_COUNT, 10);
v8FeatureListWithDefaults.set(ConductorFeatures.ACCOUNTS_AUTO_DISCOVERY, true);
v8FeatureListWithDefaults.set(ConductorFeatures.ASK_USER_DETAILS, false);
v8FeatureListWithDefaults.set(
  ConductorFeatures.ACCOUNTS_AUTO_DISCOVERY_LIMIT,
  3
);
v8FeatureListWithDefaults.set(ConductorFeatures.DEBUG_EVENTS, false);
v8FeatureListWithDefaults.set(ConductorFeatures.DEBUG_EVENTS_FIRE_HEARTBEAT, false);
// layout feature list
export const layoutFeatureList = new Map<String, any>();
layoutFeatureList.set(JOURNEY_LAYOUTS.V3, v3LayoutFeatureListWithDefaults);
layoutFeatureList.set(JOURNEY_LAYOUTS.V5, v5FeatureListWithDefaults);
layoutFeatureList.set(JOURNEY_LAYOUTS.V6, v6FeatureListWithDefaults);
layoutFeatureList.set(JOURNEY_LAYOUTS.V2, v2FeatureListWithDefaults);

layoutFeatureList.set(JOURNEY_LAYOUTS.V7, v7FeatureListWithDefaults);
layoutFeatureList.set(JOURNEY_LAYOUTS.V8, v8FeatureListWithDefaults);
export function DefaultFeatures() {
  function getListOfDefaults(layout: String) {
    return layoutFeatureList.get(layout) || new Map<String, any>();
  }

  return {
    getListOfDefaults,
  };
}
