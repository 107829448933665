import axios, { AxiosInstance, AxiosResponse } from "axios";

//commonplace for the utility or repetitive methods
export default function useUtilService() {
  const knowFipAaPreferences = new Map<string, string[]>();
  const knownTenantFipAaPrefs = new Map<string, Map<string, string[]>>();
  //util service having its own axios instance
  const http: AxiosInstance = axios.create({
    baseURL: process.env.VUE_APP_API_BASE,
    headers: {
      "Content-Type": "application/json",
    },
    timeout: 60000,
    withCredentials: false,
  });

  if (process.env.NODE_ENV === "development") {
    // Global preferences
    // knowFipAaPreferences.set("fip@finbank", ['finvu']);
    // knowFipAaPreferences.set("BARB0KIMXXX", ['finvu', 'nadl', 'anumati', 'onemoney']);
    // knowFipAaPreferences.set("finsharebank", ['nadl']);
    // knowFipAaPreferences.set("ACME-FIP", ['anumati']);
    // SBI Card Preferences
    // const sbicardPrefs = new Map<string, string[]>();
    // sbicardPrefs.set("fip@finbank", ['nadl']);
    // sbicardPrefs.set("BARB0KIMXXX", ["finvu"]);
    // knownTenantFipAaPrefs.set("sbicard", sbicardPrefs);
    //TVAM preferences
    // const tvamPref = new Map<string, string[]>();
    // tvamPref.set("fip@finbank", ['nadl']);
    //navi preferences
    // const naviPref = new Map<string, string[]>();
    // naviPref.set("fip@finbank", ['nadl']);
    // naviPref.set("sbi-fip-uat", ['nadl']);
    //knownTenantFipAaPrefs.set("tvam", tvamPref);
    //knownTenantFipAaPrefs.set("sbilife", sbicardPrefs);//TODO: replace this for sbi life
    //knownTenantFipAaPrefs.set("navi", naviPref);
  } else if (process.env.NODE_ENV === "production") {
    // add for production here, if required
    // const sbicardPrefs = new Map<string, string[]>();
    // sbicardPrefs.set("sbi-fip", ['nadl']);
    // knownTenantFipAaPrefs.set("sbicard", sbicardPrefs);
    // knownTenantFipAaPrefs.set("sbilife", sbicardPrefs);//TODO: replace this for sbi life
  }

  const knownFipPreference = new Map<string, number>();
  if (process.env.NODE_ENV === "development") {
    knownFipPreference.set("fip@finbank", 15);
    knownFipPreference.set("BARB0KIMXXX", 10);
    knownFipPreference.set("finsharebank", 8);
    knownFipPreference.set("ACME-FIP", 6);
  } else if (process.env.NODE_ENV === "production") {
    // add for production here, if required
  }

  const knownLogoMapping = new Map<string, string>();
  if (process.env.NODE_ENV === "development") {
    knownLogoMapping.set("fip@finbank", "fip-icons/icon-bank-millenium.svg");
    knownLogoMapping.set("HDFC-FIP", "fip-icons/icon-bank-hdfc.svg");
    knownLogoMapping.set("AXIS001", "fip-icons/icon-bank-axis.svg");
    knownLogoMapping.set("AUBank-FIP", "fip-icons/icon-bank-au-small.svg");
    knownLogoMapping.set("FDRLFIP", "fip-icons/icon-bank-fedral.svg");
    knownLogoMapping.set("ICICI-FIP", "fip-icons/icon-bank-icici.svg");
    knownLogoMapping.set("IDFC-FIP", "fip-icons/icon-bank-idfc-first.svg");
    knownLogoMapping.set("INDUSIND", "fip-icons/icon-bank-indusland.svg");
    knownLogoMapping.set("KOTAK-FIP", "fip-icons/icon-bank-kotak.svg");
    knownLogoMapping.set("UBI-FIP", "fip-icons/icon-bank-union.svg");
  } else if (process.env.NODE_ENV === "production") {
    // add for production here, if required
  }

  function sortFipList(fipList: any) {
    if (fipList) {
      fipList.forEach((fip: any) => {
        if (knownLogoMapping.has(fip.id)) fip.logo = knownLogoMapping.get(fip.id);
      });
      // Sort the fipList based on preference and presence of logo
      fipList.sort((a: any, b: any) => {
        // Sort by name first
        const nameA = a.name.toUpperCase(); // ignore upper and lowercase
        const nameB = b.name.toUpperCase(); // ignore upper and lowercase
        let weight = 0;
        if (nameA < nameB) {
          weight -= 1;
        } else if (nameA > nameB) {
          weight += 1;
        }

        // Sort if they have logo
        if (a.logo && !b.logo) {
          weight -= 2;
        } else if (!a.logo && b.logo) {
          weight += 2;
        }

        if (process.env.NODE_ENV === "development") {
          if (knownFipPreference.has(a.id)) {
            weight -= knownFipPreference.get(a.id)!!;
          }
          if (knownFipPreference.has(b.id)) {
            weight += knownFipPreference.get(b.id)!!;
          }
        }

        // Consider preference of FIP order
        return weight;
      });
      return fipList;
    }
  }

  function getAAFromFip(
    selectedFips: any[],
    availableFips: any[],
    availableAAs: {
      handle: string;
      id: string;
      logo?: string | undefined;
      version?: string | undefined;
    }[],
    tenantId: string,
    requestId?: string
  ) {
    // if no AA is applicable or no fip mappings are available from the server side
    // use the mappings available in UI
    const filtered = selectedFips.flatMap((sf) => availableFips.filter((af) => af.id === sf));
    if (filtered.length === 0) {
      // wild stuff! how does this happens?
      //System.exit(-1);
      throw new Error("UNSUPPORTED_FIP");
    }

    // if tenant preferences are available
    let preferredAa = [] as string[][];
    if (tenantId && knownTenantFipAaPrefs.has(tenantId)) {
      const tenantPrefs = knownTenantFipAaPrefs.get(tenantId)!;
      preferredAa = getPrefAAList(filtered, tenantPrefs);
    }
    if (preferredAa.length === 0 && knowFipAaPreferences.size > 0) {
      // if tenant preferences are not available , using the global ones
      preferredAa = getPrefAAList(filtered, knowFipAaPreferences);
    }
    if (preferredAa.length === 0) {
      // using the AAs present in the FIP list
      preferredAa = filtered.map((data) => {
        return data.aa;
      });
    }
    const commonAa = commonAmongAAs(preferredAa);
    return commonAa.length > 0 ? commonAa : availableAAs[0].handle;
  }
  // take out the common AA from the list of AAs
  function commonAmongAAs(listOfAAsArray: string[][]) {
    const finalResolution = listOfAAsArray.reduce((p, c) => p.filter((e) => c.includes(e)));
    return finalResolution[0] || "";
  }

  function getPrefAAList(filtered: any[], fipMappings: Map<string, string[]>): string[][] {
    const prefList = [];
    for (const value of filtered) {
      if (fipMappings.has(value.id)) {
        const aaList = checkIfAAIsPresent(fipMappings.get(value.id) || [], value.aa);
        if (aaList.length > 0) {
          prefList.push(aaList); // get the array of arrays of multiple AAs
        }
      }
    }
    return prefList;
  }

  function getEncrypedParams(url: string) {
    const urlParams = new URL(url);
    let encryptedFiuId: any;
    let encryptedRequest: any;
    let requestDate: any;
    if (urlParams.searchParams.get("fi") !== null) {
      encryptedFiuId = urlParams.searchParams.get("fi");
    }
    if (urlParams.searchParams.get("ecreq") !== null) {
      encryptedRequest = urlParams.searchParams.get("ecreq");
    }
    if (urlParams.searchParams.get("reqdate") !== null) {
      requestDate = urlParams.searchParams.get("reqdate");
    }
    return {
      encryptedFiuId: encryptedFiuId,
      encryptedRequest: encryptedRequest,
      requestDate: requestDate,
    };
  }

  function groupByProperty(objectArray: any[], property: string) {
    return objectArray.reduce((acc: { [x: string]: any[] }, obj: { [x: string]: any }) => {
      const key = obj[property];
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(obj);
      return acc;
    }, {});
  }

  function getUniqueElementsByKeys(arr: any[], props: string[]) {
    const result: any[] = [];
    const uniqueKeySet: Set<string> = new Set();

    for (const obj of arr) {
      const keyString = props.map((prop) => obj[prop]).join("|");
      if (!uniqueKeySet.has(keyString)) {
        uniqueKeySet.add(keyString);
        const uniqueElement: any = {};
        for (const prop of props) {
          uniqueElement[prop] = obj[prop];
        }
        result.push(uniqueElement);
      }
    }

    return result;
  }

  function getFipLogo(fipId: any, availableFips: any[]) {
    if (fipId) {
      const fip = availableFips.find((data: { id: any }) => data.id === fipId);
      if (fip.logo) {
        return fip.logo;
      }
    }
  }

  function loadAAFips(availableFips: any[], aaHandle: any, aaFipMappings: { [x: string]: any }): any[] {
    const list = availableFips.filter((bank: { id: any; aa?: string[] }) => {
      if (!bank.aa) {
        return true;
      }
      // const fipBankId = aaFipMappings[aaHandle];
      // return fipBankId.some((fip: any) => fip === bank.id);
      return bank.aa.indexOf(aaHandle) !== -1;
    });
    return list;
  }

  function filterFipsBasedOnAA(fipList: any, availableAAs: any[], aaFipMappings: { [s: string]: unknown } | ArrayLike<unknown>) {
    let list: any[] = [];
    let final = [];
    for (const [key, value] of Object.entries(aaFipMappings)) {
      const aaAvailable = availableAAs.filter((aa: { handle: string }) => aa.handle === key);
      if (aaAvailable.length) {
        list = list.concat(value);
      }
    }

    final = fipList.filter((el: { id: any }) => {
      return list.find((element: any) => {
        return element === el.id;
      });
    });
    return final;
  }

  function NumberOnly(evt: any) {
    evt = evt ? evt : window.event;
    const charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
      evt.preventDefault();
    } else {
      return true;
    }
  }

  const eventPayload = (requestId: string, fields?: Record<string, any>) => {
    return {
      requestId,
      timestamp: new Date().toISOString(),
      ...fields,
    };
  };

  function validatePayloadForAutoReadOTP(e: any) {
    if (e && typeof e.value === "string") {
      if (e.value.match(/^[0-9]+$/)) {
        return true;
      } else {
        return false;
      }
    }
  }

  function addQueryParams(redirectUrl: string, keys: string[], values: any[]) {
    const existing = redirectUrl.lastIndexOf("?") > redirectUrl.lastIndexOf("#") ? redirectUrl.substring(redirectUrl.lastIndexOf("?") + 1) : "";

    const query = new URLSearchParams(existing);
    for (const index in keys) {
      query.set(keys[index], values[index]);
    }
    return `${redirectUrl.replace(`?${existing}`, "")}?${query.toString()}`;
  }

  function getMagicNumber(count: number) {
    if (count > 0) {
      const min = 1,
        max = count + 1;
      const magic = Math.floor(Math.random() * (max - min)) + min;
      return magic - 1;
    }
  }

  function checkIfAAIsPresent(listOfAA: string[], availableAAsForFip: any) {
    const aaPresent = new Map<string, boolean>();
    availableAAsForFip.forEach((data: string) => {
      aaPresent.set(data, false);
    });

    listOfAA.forEach((ele) => {
      if (aaPresent.has(ele)) {
        aaPresent.set(ele, true);
      }
    });
    let activeAAs: string[] = [];
    aaPresent.forEach((value, key) => {
      if (value) {
        activeAAs.push(key);
      }
    });

    if (activeAAs.length === 0) {
      activeAAs = Array.from(aaPresent.keys());
    }

    return activeAAs;
  }
  // tenant specific : AA for navi FIX: Remove Later
  function getAAForNavi(selectedFips: any[], availableFips: any[], availableAAs: any, tenantId: string) {
    const selectedAA = getAAFromFip(selectedFips, availableFips, availableAAs, tenantId);
    if (selectedAA === "finvu" || selectedAA === "nadl") {
      return selectedAA;
    }
    return "finvu";
  }

  function loadFipsBasedOnFITypes(fiTypeList: string[], fipList: any[]) {
    if (fiTypeList) {
      const eligibleFipList = [];
      const activeFiTypes = new Set(fiTypeList);
      for (let i = 0; i < fipList.length; i++) {
        const fip = fipList[i] as any;
        const fiTypes = fip.fiTypes;
        let flag = false;
        for (let j = 0; j < fiTypes.length; j++) {
          if (activeFiTypes.has(fiTypes[j])) {
            flag = true;
            break;
          }
        }

        if (flag) eligibleFipList.push(fipList[i]);
      }
      return eligibleFipList;
    } else {
      return fipList;
    }
  }

  const knownFiTypeShortHands = new Set(["SIP", "CP", "ETF", "IDR", "CIS", "AIF", "NPS", "INVIT", "REIT"]);

  function humanizeAccountType(type: string, subtype: string) {
    if (type.toUpperCase() == "DEPOSIT") {
      if (subtype.toUpperCase() == "SAVINGS") return "Savings Account";
      else return "Current Account";
    } else if (knownFiTypeShortHands.has(type.toUpperCase())) {
      return type.toUpperCase();
    } else {
      return type
        .split(/[\s-_]+/) // split by whitespace and hyphens, and underscore
        .map((v) => v.toLowerCase())
        .map(capitalizeFirstLetter)
        .join(" ");
    }
  }

  function capitalizeFirstLetter(input: string) {
    return input[0].toUpperCase() + input.slice(1);
  }

  function indianizeMobileNumber(input: string) {
    if (input) {
      return input.length > 10 ? input : "+91 " + input;
    }
    return input;
  }

  function getMaskedMobileNumber(mobile: string) {
    const endDigits = mobile.slice(-3);
    return endDigits.padStart(mobile.length, "*");
  }

  function loadFipsBasedOnAA(fiTypes: string[], institutions: any[], aaHandle: string) {
    let fiBankList = loadFipsBasedOnFITypes(fiTypes, institutions) || [];
    fiBankList = fiBankList.filter((fip) => {
      if (!fip.aa) {
        return true; // we don't have sufficient information
      } else if (fip.aa.includes(aaHandle)) {
        return true; // this is applicable
      }
      return false;
    });
    return fiBankList;
  }

  function filterBasedOnProperty(data: any[], filters: any) {
    return data.filter((element) => {
      for (const filter of filters) {
        if (element[filter.property] !== filter.value) {
          return false;
        }
      }
      return true;
    });
  }

  function splitTemplateString(data: any, start?: number | undefined, end?: number | undefined) {
    const objValue = {} as any;
    for (const [keyValue, value] of data) {
      const parts = keyValue.split(".");
      let slicedParts;
      if (start !== undefined && end !== undefined) {
        slicedParts = parts.slice(start, end);
      } else if (start !== undefined) {
        slicedParts = parts.slice(start);
      } else if (end !== undefined) {
        slicedParts = parts.slice(parts.length - end);
      } else {
        // Handle the case when both start and end are undefined
        slicedParts = parts;
      }
      const key = slicedParts.join("_");
      objValue[key] = value; // You can assign any value you want here
    }
    return objValue;
  }

  function getDeviceType() {
    const userAgent = navigator.userAgent;
    if (/android/.test(userAgent) || /iPad|iPhone|iPod/.test(userAgent)) {
      return "mobile";
    }
    return "web";
  }
  function groupFipsBasedOnFITYpes(fipList: any[]) {
    const groupedByFiTypes = fipList.reduce((acc, obj) => {
      obj.fiTypes.forEach((fiType: any) => {
        if (!acc[fiType]) {
          acc[fiType] = { fiType, banks: [] };
        }
        acc[fiType].banks.push(obj);
      });
      return acc;
    }, {});

    // Convert values to an array
    const outputArray = Object.values(groupedByFiTypes);
    return outputArray;
  }

  function checkForValidURL(finalUrl: string) {
    let redirectUrl = finalUrl;
    if(finalUrl){
        if (!finalUrl.startsWith("http://") && !finalUrl.startsWith("https://")) {
            redirectUrl = "https://" + finalUrl;
        }
    }
    return redirectUrl;
  }

  function capitalizeSentence(sentence:string){
    const wordsArray = sentence.split(" ");

    const capitalizedWordsArray = wordsArray.map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    });

    const capitalizedString = capitalizedWordsArray.join(" ");
    return capitalizedString;
  }

  function convertToSentanceCase(str: string, appendChar: string) {
    str = str.toLowerCase();
    str = str.charAt(0).toUpperCase() + str.slice(1);
    if (appendChar) {
      const lastCommaIndex = str.lastIndexOf(",");
      if (lastCommaIndex !== -1) {
        str = str.slice(0, lastCommaIndex) + ` ${appendChar}` +
          str.slice(lastCommaIndex + 1);
      } else {
        const lastSpaceIndex = str.lastIndexOf(" ");
        if (lastSpaceIndex !== -1) {
          str = str.slice(0, lastSpaceIndex) + ` ${appendChar}` + str.slice(lastSpaceIndex);
        }
      }
    }

    return str;
  }

  function removeBrackets(str: string) {
    const regex = /\((.*?)\)/g;
    return str.replace(regex, (match: any, content: any) => content);
  }

  function getKeyFromValue(map: any[] | Map<string, string>, value: string) {
    for (const [key, val] of map.entries()) {
      if (val === value) {
        return key;
      }
    }
    return undefined;
  }

  function checkForConsentedAccounts(listOfAlreadyConsentedAccounts: any[], accountToCheck: { fipId: any; maskedAccNumber: any; FIType: any; }) {
    return listOfAlreadyConsentedAccounts.find((account: { fipId: any; maskedAccNumber: any; fiType: any; }) => 
      account.fipId === accountToCheck.fipId &&
      account.maskedAccNumber === accountToCheck.maskedAccNumber &&
      account.fiType === accountToCheck.FIType
    ) !== undefined;
  }

  return {
    sortFipList,
    getAAFromFip,
    getEncrypedParams,
    groupByProperty,
    getFipLogo,
    loadAAFips,
    filterFipsBasedOnAA,
    NumberOnly,
    eventPayload,
    validatePayloadForAutoReadOTP,
    addQueryParams,
    getMagicNumber,
    commonAmongAAs,
    checkIfAAIsPresent,
    loadFipsBasedOnFITypes,
    humanizeAccountType,
    indianizeMobileNumber,
    getMaskedMobileNumber,
    loadFipsBasedOnAA,
    getUniqueElementsByKeys,
    filterBasedOnProperty,
    splitTemplateString,
    getDeviceType,
    capitalizeFirstLetter,
    checkForValidURL,
    capitalizeSentence,
    groupFipsBasedOnFITYpes,
    convertToSentanceCase,
    removeBrackets,
    getKeyFromValue,
    checkForConsentedAccounts
  };
}
