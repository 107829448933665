export const journeyTemplates = new Map<string, any>();

//Nomeclature -  view.componentAttributeName

//v2u
//v2u titles
journeyTemplates.set(
  "v2u.consent.title",
  "Consent to access your Financial Data"
);
journeyTemplates.set(
  "v2u.consent.grant.success",
  "Data is shared successfully"
);
journeyTemplates.set(
  "v2u.consent.grant.processing",
  "Please wait while your bank shares the data"
);
journeyTemplates.set(
  "v2u.consent.grant.error",
  "Your bank faced error while sharing the data"
);

journeyTemplates.set("v2u.consent.availableAccounts"," Available Bank Account(s)")

journeyTemplates.set(
  "v2u.addBanks.title",
  "Choose more Financial Institutions"
);

journeyTemplates.set(
  "v2u.addBanks.subTitle",
  " Linking as many accounts as possible will help you make the most of the benefits available"
)
journeyTemplates.set(
  "v2u.addBanks.banksLinked",
  "Bank Accounts Linked"
)
journeyTemplates.set(
  "v2u.addBanks.search",
  "Search your bank if it is not visible here."
)
journeyTemplates.set("v2u.addBanks.noBanksFound", " No banks available at the moment, please try again in sometime.")
journeyTemplates.set("v2u.login.title", "Verify yourself");
journeyTemplates.set(
  "v2u.login.shortDesc",
  "Verify to link accounts and approve consent"
);
journeyTemplates.set(
  "v2u.login.otpSentText",
  "Please enter the OTP sent to"
);
journeyTemplates.set("v2u.login.btnPrimaryTxt", "Verify");
journeyTemplates.set("v2u.discovery.title", "Link your accounts");
journeyTemplates.set("v2u.discovery.subtitle", " Please select your financial institutions linked with your mobile no(s)");
journeyTemplates.set("v2u.discovery.success", "Bank Account(s) Linked Successfully")
journeyTemplates.set("v2u.sf.title", "Choose your Bank");
journeyTemplates.set("v2u.sf.shortTitle", "Select your bank");
journeyTemplates.set("v2u.sf.btnPrimaryTxt", "Proceed");
journeyTemplates.set("v2u.sf.subTitle", "Please select the Bank(s) you have account(s) in");
journeyTemplates.set("v2u.sf.unSupported", " Bank Unsupported, Upload-Manually");
journeyTemplates.set('v2u.sf.noAccountsFound', "No banks available at the moment, please try again in sometime.");
journeyTemplates.set('v2u.sf.search', "Searched bank not found.");
journeyTemplates.set("v2u.ei.title", "Account Details"); // for extra details modal,
journeyTemplates.set(
  "v2u.ei.description",
  "Enter details as provided to the FIP, so that your accounts can be discovered."
);
journeyTemplates.set("v2u.discovery.info", "Accounts are already linked")
journeyTemplates.set("v2u.discovery.search", "No banks found")



// login page
journeyTemplates.set("v3.login.pageTitle", "Verify Bank Details");
journeyTemplates.set(
  "v3.login.pageSubTitle",
  "We need this to verify your bank account"
);
journeyTemplates.set("v3.login.otpTitle", "Enter OTP");
journeyTemplates.set("v3.login.otpSubHeading", "Sent to your mobile number");
journeyTemplates.set("v3.login.otpSubHeadingShowChangeNumber", false);
journeyTemplates.set("v3.login.primaryCTA", "Verify");
journeyTemplates.set("v3.login.secondaryCTA", "Back");
journeyTemplates.set("v3.login.note.dataStyle", "");
journeyTemplates.set("v3.login.note.headingStyle", "hidden primary-color");
journeyTemplates.set("v3.login.consent.footerText.purpose_104", "The consent will be used only during the tenure of the loan, if the EMI is overdue.");

/* banks view */
journeyTemplates.set("v3.selectFips.searchText", "No search results found");
journeyTemplates.set("v3.selectFips.noAccountsImg", false);
journeyTemplates.set("v3.selectFips.primaryCTA", "Next");
journeyTemplates.set("v3.selectFips.secondaryCTA", "Back");
journeyTemplates.set("v3.selectFips.pageTitle", "Available Banks");
journeyTemplates.set("v3.selectFips.showActions", true);

/* accounts view */

journeyTemplates.set("v3.accounts.pageTitle", "Share your Bank Statement");
journeyTemplates.set(
  "v3.accounts.pageSubtitle",
  "Please choose your bank account linked with"
);
journeyTemplates.set("v3.accounts.showAddNumber", false);
journeyTemplates.set("v3.accounts.primaryCTA", "Verify");
journeyTemplates.set("v3.accounts.secondaryCTA", "Back");
journeyTemplates.set("v3.accounts.addBank", false);
journeyTemplates.set("v3.accounts.enableAddBank", true);
journeyTemplates.set("v3.accounts.showIcon", false);
journeyTemplates.set("v3.accounts.bankList.title", "Available Bank Accounts");
/* Modals */
journeyTemplates.set("v3.modal.altMobile.title", "Add mobile number");
journeyTemplates.set("v3.modal.altMobile.titleShowIcon", "true");
journeyTemplates.set(
  "v3.modal.altMobile.subTitle",
  "We’ll use this mobile number to discover your bank accounts"
);
journeyTemplates.set("v3.modal.altMobile.inputTitle", "");
journeyTemplates.set("v3.modal.altMobile.primaryCTA", "Add Number");
journeyTemplates.set("v3.modal.altMobile.secondaryCTA", "Cancel");

/* Bank otp dialog */
journeyTemplates.set("v3.modal.bankOTP.title", "Enter OTP");
journeyTemplates.set("v3.modal.bankOTP.primaryCTA", "Verify OTP");
journeyTemplates.set("v3.modal.bankOTP.secondaryCTA", "Skip Bank");
journeyTemplates.set("v3.modal.bankOTP.showLinkingCount", false);
/* Unable OTP dialog */
journeyTemplates.set("v3.modal.unableOtpDialog.title", "");
journeyTemplates.set("v3.modal.unableOtpDialog.subTitle", "");
journeyTemplates.set("v3.modal.unableOtpDialog.primaryCTA", "Try Again");
journeyTemplates.set(
  "v3.modal.unableOtpDialog.secondaryCTA",
  "Upload Manually"
);

/* Add Mobile Dialog */
journeyTemplates.set("v3.modal.addMobileDialog.title", "Add mobile number");
journeyTemplates.set(
  "v3.modal.addMobileDialog.subTitle",
  "You can add more banks linked to another mobile number"
);
journeyTemplates.set("v3.modal.addMobileDialog.primaryCTA", "Add");
journeyTemplates.set("v3.modal.addMobileDialog.secondaryCTA", "Cancel");
journeyTemplates.set("v3.modal.addMobileDialog.titleShowIcon", false);

/* Error Dialog */
journeyTemplates.set(
  "v3.modal.errorDialog.title",
  "Getting your account details"
);
journeyTemplates.set("v3.modal.errorDialog.subTitle", "");
journeyTemplates.set("v3.modal.errorDialog.primaryCTA", "");
journeyTemplates.set("v3.modal.errorDialog.secondaryCTA", "");

/* No Accounts Dialog */
journeyTemplates.set("v3.modal.noAccounts.title", "");
journeyTemplates.set("v3.modal.noAccounts.subTitle", "");
journeyTemplates.set("v3.modal.noAccounts.primaryCTA", "");
journeyTemplates.set("v3.modal.noAccounts.secondaryCTA", "");

// just a dummy one to test out with other templates
journeyTemplates.set("v5.login.pagetitle", "Verify Your Number");
journeyTemplates.set("v6.login.pagetitle", "Verify Your Number");

/* For Layout V7 */

// AAInfo View
journeyTemplates.set("v7.aaInfo.pageTitle", "Welcome!");
journeyTemplates.set("v7.aaInfo.infoText", [
  { text: "AA is a RBI-authorised Account Aggregator" },
  { text: "AA can not read or store any of your data" },
  { text: "Secure and quick sharing" },
]);
journeyTemplates.set(
  "v7.aaInfo.note",
  "Used by 1 million + customers across India"
);
journeyTemplates.set("v7.aaInfo.aa.title", "What are Account Aggregators?");
journeyTemplates.set(
  "v7.aaInfo.aa.description",
  "RBI authorised institutions that securely find and share your account data with us"
);
journeyTemplates.set(
  "v7.aaInfo.note",
  "Used by 1 million+ customers across India"
);
journeyTemplates.set("v7.aaInfo.primaryCTA", "Verify Yourself");

// login view
journeyTemplates.set("v7.login.pageTitle", "Welcome!");
journeyTemplates.set("v7.login.pageHeading", "Verify yourself");
journeyTemplates.set(
  "v7.login.note1",
  "OTP will be sent via SMS to verify the mobile number!"
);
journeyTemplates.set("v7.login.note2", "OTP has been sent to your mobile no.");
journeyTemplates.set("v7.login.mobileInputTitle", "Mobile number");
journeyTemplates.set("v7.login.otpInputTitle", "Enter OTP");
journeyTemplates.set("v7.login.otpErrorText", "Invalid OTP");
journeyTemplates.set("v7.login.otpPrimaryCTA", "Verify");
journeyTemplates.set("v7.login.otpPrimaryCTA", "Verify");
journeyTemplates.set("v7.login.pagePrimaryCTA", "Get OTP");

//login otp verified modal
journeyTemplates.set(
  "v7.login.modal.otpVerified.title",
  "Verified successfully"
);
journeyTemplates.set(
  "v7.login.modal.otpVerified.subTitle",
  "number is verified successfully"
);
journeyTemplates.set("v7.login.modal.otpVerified.primaryCTA", "Next");

// accounts view
journeyTemplates.set("v7.accounts.pageTitle", "Link your accounts");
journeyTemplates.set(
  "v7.accounts.pageHeading",
  "Following accounts have been discovered with the mobile no."
);

//linking modal view
journeyTemplates.set("v7.accounts.modal.linking.title", "Enter OTP");
journeyTemplates.set("v7.accounts.modal.linking.otpInputTitle", "Enter OTP");
journeyTemplates.set(
  "v7.accounts.modal.linking.subTitle",
  "Securely authorize each selected account"
);
journeyTemplates.set(
  "v7.accounts.modal.linking.note1",
  "We have sent 6 digit OTP to your mobile no."
);
journeyTemplates.set("v7.accounts.modal.linking.primaryCTA", "Verify");

//linking success
journeyTemplates.set(
  "v7.accounts.modal.linkingSuccess.title",
  "Linked successfully"
);
journeyTemplates.set(
  "v7.accounts.modal.linkingSuccess.subTitle",
  "Your bank account has been linked successfully"
);
journeyTemplates.set("v7.accounts.modal.linkingSuccess.primaryCTA", "Done");

//already linked

journeyTemplates.set(
  "v7.accounts.modal.alreadyLinked.title",
  "Accounts are already linked"
);
journeyTemplates.set(
  "v7.accounts.modal.alreadyLinked.subTitle",
  "Your bank account has already been linked, move to next tab"
);
journeyTemplates.set("v7.accounts.modal.alreadyLinked.primaryCTA", "Done");

// linking failed
journeyTemplates.set("v7.accounts.modal.failedLinking.title", "Account Failed");
journeyTemplates.set(
  "v7.accounts.modal.failedLinking.subTitle",
  "We're sorry, but we couldn't link your account at this time. This might be due to a temporary issue. Try Another fip for time being"
);
journeyTemplates.set("v7.accounts.modal.failedLinking.primaryCTA", "Try Later");

// consent details view
journeyTemplates.set("v7.consentdetails.pageTitle", "Consent details");
journeyTemplates.set(
  "v7.consentdetails.pageHeading",
  "DSP Asset Managers will access your linked"
);
journeyTemplates.set("v7.consentdetails.note1", "Your data will be shared");
journeyTemplates.set(
  "v7.consentdetails.accountDetails.title",
  "Accounts Shared"
);
journeyTemplates.set(
  "v7.consentdetails.details.title",
  "What we need from you,"
);
journeyTemplates.set("v7.consentdetails.primaryCTA", "Give Permission");
journeyTemplates.set("v7.consentdetails.secondaryCTA", "Decline");

// decline confirmation modal

journeyTemplates.set(
  "v7.consentdetails.modal.declineConfirmation.title",
  "Are you sure, you want to decline it"
);
journeyTemplates.set(
  "v7.consentdetails.modal.declineConfirmation.note1",
  "You won't be able to get your investment analysed by DSP"
);
journeyTemplates.set(
  "v7.consentdetails.modal.declineConfirmation.primaryCTA",
  "Yes, I want to Decline"
);
journeyTemplates.set(
  "v7.consentdetails.modal.declineConfirmation.secondaryCTA",
  "Go Back"
);

// declined consent modal
journeyTemplates.set(
  "v7.consentdetails.modal.declinedConsent.title",
  "You have declined the account sharing request with DSP Asset Managers"
);
journeyTemplates.set(
  "v7.consentdetails.modal.declinedConsent.note1",
  "You can always share these accounts again through the new link provided by DSP Asset Managers"
);
journeyTemplates.set(
  "v7.consentdetails.modal.declinedConsent.primaryCTA",
  "Done"
);

// consent approved modal
journeyTemplates.set(
  "v7.consentdetails.modal.approveConsent.title",
  "Data shared successfully"
);
journeyTemplates.set(
  "v7.consentdetails.modal.approveConsent.note1",
  "Your bank details have been shared with DSP Asset Managers successfully"
);
journeyTemplates.set(
  "v7.consentdetails.modal.approveConsent.primaryCTA",
  "Done"
);

// altmobile
journeyTemplates.set("v7.altMobile.pageTitle", "Welcome!");
journeyTemplates.set("v7.altMobile.pageHeading", "Verify yourself");
journeyTemplates.set(
  "v7.altMobile.note1",
  "OTP will be sent via SMS to verify the mobile number!"
);
journeyTemplates.set(
  "v7.altMobile.note2",
  "OTP has been sent to your mobile no."
);
journeyTemplates.set("v7.altMobile.mobileInputTitle", "Enter Mobile Number");
journeyTemplates.set("v7.altMobile.otpInputTitle", "Enter OTP");
journeyTemplates.set("v7.altMobile.otpErrorText", "Invalid OTP");
journeyTemplates.set("v7.altMobile.otpPrimaryCTA", "Verify");
journeyTemplates.set("v7.altMobile.otpPrimaryCTA", "Verify");
journeyTemplates.set("v7.altMobile.pagePrimaryCTA", "Get OTP");
journeyTemplates.set("v7.altMobile.otpValidationErrorText", "Invalid OTP");

//alt mobile otp verified modal
journeyTemplates.set(
  "v7.altMobile.modal.otpVerified.title",
  "Verified successfully"
);
journeyTemplates.set(
  "v7.altMobile.modal.otpVerified.subTitle",
  "number is verified Successfully"
);
journeyTemplates.set("v7.altMobile.modal.otpVerified.primaryCTA", "Next");
// layout template list
export const layoutTemplatesList = new Map<string, any>();

export function DefaultTemplates() {
  function getListOfDefaultTemplates(layoutId: string) {
    for (const [key, value] of journeyTemplates) {
      if (layoutId) {
        if (key.includes(layoutId)) {
          if (!layoutTemplatesList.get(layoutId)) {
            const templateMap = new Map<string, any>();
            templateMap.set(key, value);
            layoutTemplatesList.set(layoutId, templateMap);
          } else {
            const templateMap = layoutTemplatesList.get(layoutId);
            templateMap.set(key, value);
            layoutTemplatesList.set(layoutId, templateMap);
          }
        }
      }
    }
    return layoutTemplatesList.get(layoutId);
  }

  return {
    getListOfDefaultTemplates,
  };
}
